import { reactive } from 'vue'
import { clearAxiosInstances } from '@/plugins/axios'

// 服务器类型定义
export const ServerTypes = {
  COLLECT: 'collect',
  COMPUTE: 'compute',
  EXPERIMENT: 'experiment'
};


// 默认服务器配置
const defaultServers = {
  collect: {
    key: 'collect',
    type: ServerTypes.COLLECT,
    name: '收集',
    label: '数据收集服务器',
    apiEndpoint: '/a/',
    wsEndpoint: '/ws/a/commandstream',
    order: 1
  },
};

// 创建响应式存储
export const serverStore = reactive({
  servers: {...defaultServers},
  
  // 获取所有服务器
  getAllServers() {
    return Object.values(this.servers);
  },

  // 获取服务器配置
  getServer(key) {
    return this.servers[key];
  },

  // 添加服务器
  addServer(config) {
    if (!config.key || !config.type) {
      throw new Error('无效的服务器配置');
    }
    this.servers[config.key] = config;
    this.saveToStorage();
    clearAxiosInstances(); // 清除已有的axios实例
  },

  // 删除服务器
  removeServer(key) {
    if (defaultServers[key]) {
      throw new Error('不能删除默认服务器');
    }
    delete this.servers[key];
    this.saveToStorage();
    clearAxiosInstances(); // 清除已有的axios实例
  },

  // 保存到本地存储
  saveToStorage() {
    localStorage.setItem('serverConfig', JSON.stringify(this.servers));
  },

  // 从本地存储加载
  loadFromStorage() {
    const stored = localStorage.getItem('serverConfig');
    if (stored) {
      try {
        this.servers = JSON.parse(stored);
      } catch (e) {
        console.error('加载服务器配置失败:', e);
        this.resetToDefault();
      }
    }
  },

  // 重置为默认配置
  resetToDefault() {
    this.servers = {...defaultServers};
    this.saveToStorage();
  },

  // 获取用于 axios 的 endpoints 配置
  getEndpoints() {
    return Object.entries(this.servers).reduce((acc, [key, server]) => {
      acc[server.name] = server.apiEndpoint;
      return acc;
    }, {});
  },

  // 获取用于 websocket 的 URLs 配置
  getWebsocketUrls(isDevelopment = false, proxyUrl = '') {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const host = window.location.host;
    const baseUrl = isDevelopment && proxyUrl ? proxyUrl : `${protocol}//${host}`;

    return Object.entries(this.servers).reduce((acc, [key, server]) => {
      // 确保 wsEndpoint 以 / 开头
      const endpoint = server.wsEndpoint.startsWith('/') ? server.wsEndpoint : `/${server.wsEndpoint}`;
      acc[server.name] = `${baseUrl}${endpoint}`;
      return acc;
    }, {});
  }
});

// 初始化时加载存储的配置
serverStore.loadFromStorage();