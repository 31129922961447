<template>
  <el-card shadow="always">
    <div v-if="current_detail==''">
      <UploadPsg/>
      <br/>
    </div>
    <div v-show="current_detail==''">
      <CustomFilter item="psgfile" @filter-changed="handleFilterChange    "/>
      <el-table :data="rows" table-layout="auto">
        <el-table-column type="selection" width="30"/>

        <el-table-column prop="status" label="文件" sortable :sort-method="sortNames">
          <template #default="scope">
            <el-text>{{ scope.row.psgfile }}</el-text>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="文件状态" sortable :sort-method="sortNames">
          <template #default="scope">
            <PsgSummary :info="scope.row"/>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="文件状态" sortable :sort-method="sortNames">
          <template #default="scope">
            <TagViewer :info="scope.row['文件状态']"/>
          </template>
        </el-table-column>

        <el-table-column prop="rules" label="MCU对齐">
          <template #default="scope">
            <TagViewer :info="scope.row['MCU对齐']"/>
          </template>
        </el-table-column>
        <el-table-column prop="rules" label="计算状态">
          <template #default="scope">
            <TagViewer :info="scope.row['计算状态']"/>
          </template>
        </el-table-column>
        <el-table-column prop="logs" label="日志" sortable :sort-method="sortNames">
          <template #default="scope">
            <DeviceLog :info="scope.row"/>
            <el-button @click="navigateToPsgReport(scope.row.psgfile)">查看报告</el-button>
          </template>

        </el-table-column>

      </el-table>
      <el-pagination v-model:current-page="currentPage"
                     :page-size="10"
                     layout="total, prev, pager, next"
                     :total="total"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"></el-pagination>
    </div>
    <div v-if="current_detail!=''">
      <PsgReportView :psgid="current_detail" @close="handleClose"/>
    </div>
  </el-card>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

// const {proxy} = getCurrentInstance(); // 获取组件实例的代理对象

// 一个示例函数，演示如何读取路由参数并使用router进行导航

import CustomFilter from '@/common/CustomFilter.vue';
import TagViewer from '@/common/TagViewer.vue';
import DeviceLog from './DeviceLog.vue';
import PsgReportView from './PsgReportView.vue';
import UploadPsg from './UploadPsg.vue';
import PsgSummary from '@/components/PsgSummary.vue';
import { useJumpData } from '@/plugins/checkJumpData';
import { createAxiosInstance } from '@/plugins/axios';

const { checkJumpData } = useJumpData();
// 定义响应式数据
const currentPage = ref(1);
const pageSize = ref(10);
const total = ref(100);
const rows = ref([]);
const current_detail = ref('');

let intervalId = null; // 用于存储定时器ID
let encodedFilterStr = '';
let fetching = 0;

// 从云端获取数据的方法
const fetchData = async () => {
  if (fetching) return;
  try {
    fetching = 1
    const axiosInstance = createAxiosInstance('收集');

    const response = await axiosInstance.get('/psgfile/?filter=' + encodedFilterStr + '&page=' + currentPage.value)
    fetching = 0;
    processResponse(response);
  } catch (error) {
    console.error('获取数据失败:', error);
    fetching = 0;
  }
};

// 处理响应数据
const processResponse = (response) => {
  rows.value = response.data.data.nodes;
  total.value = response.data.data.count;
};

// 处理过滤器变化
const handleFilterChange = async (filterStr) => {
  encodedFilterStr = encodeURIComponent(filterStr);
  fetchData()
};

// 处理分页大小变化
const handleSizeChange = (newSize) => {
  pageSize.value = newSize;
  fetchData();
};

// 处理当前页变化
const handleCurrentChange = (val) => {
  currentPage.value = val;
  fetchData();
};

// 排序方法
const sortNames = (a, b) => {
  return (a.user + '').localeCompare(b.user + '');
};

onMounted(() => {
  checkJumpData()
  fetchData();
  intervalId = setInterval(fetchData, 3000);
});

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});

function navigateToPsgReport (psgid) {
  current_detail.value = psgid;
}

const handleClose = () => {
  current_detail.value = ''
}

</script>

<style>
/* 定义鼠标悬停在单元格上时的样式 */
.el-table .cell:hover {
  background-color: #f5f5f5; /* 鼠标悬停时的背景色 */
  cursor: pointer; /* 将鼠标光标变为手形 */
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
