import { extract_file } from '@/plugins/timebuffer';

// 单例数据库实例
let dbInstance = null;
const lruCache = new Map();
const MAX_CACHE_SIZE = 1000; // LRU 缓存的最大条目数
let active_thread = 0;
// 打开数据库（单例模式）
async function openDatabase () {
  if (dbInstance) {
    return dbInstance;
  }

  return new Promise((resolve, reject) => {
    const request = indexedDB.open('fileCacheDB', 1);
    request.onerror = () => reject('数据库打开失败');
    request.onsuccess = () => {
      dbInstance = request.result;
      resolve(dbInstance);
    };
    request.onupgradeneeded = () => {
      const db = request.result;
      db.createObjectStore('fileCache', { keyPath: 'url' });
      db.createObjectStore('metaData', { keyPath: 'key' });
    };
  });
}

// LRU 缓存管理
function addToCache (url, data) {
  if (lruCache.has(url)) {
    lruCache.delete(url);
  }
  lruCache.set(url, data);
  if (lruCache.size > MAX_CACHE_SIZE) {
    // 删除最近最少使用的条目
    const oldestKey = lruCache.keys().next().value;
    lruCache.delete(oldestKey);
  }
}

function getFromCache (url) {
  if (lruCache.has(url)) {
    const data = lruCache.get(url);
    // 更新缓存顺序
    lruCache.delete(url);
    lruCache.set(url, data);
    return data;
  }
  return null;
}

// 存储解析后的对象及其 Last-Modified 时间
async function storeExtractedObject (db, url, extractedObject, lastModified) {
  const transaction = db.transaction('fileCache', 'readwrite');
  const store = transaction.objectStore('fileCache');
  store.put({ url, data: extractedObject, lastModified });
  // 更新 LRU 缓存
  addToCache(url, { data: extractedObject, lastModified });
}

// 从缓存读取解析后的对象（支持 LRU 缓存）
async function getFileFromCache (db, url) {
  // 尝试从 LRU 缓存中获取数据
  const cachedData = getFromCache(url);
  if (cachedData) {
    return cachedData;
  }

  // 如果缓存未命中，从 IndexedDB 中读取
  return new Promise((resolve, reject) => {
    const transaction = db.transaction('fileCache', 'readonly');
    const store = transaction.objectStore('fileCache');
    const request = store.get(url);
    request.onsuccess = () => {
      const result = request.result;
      if (result) {
        addToCache(url, result); // 存入 LRU 缓存
      }
      resolve(result);
    };
    request.onerror = () => reject('读取缓存文件失败');
  });
}

// 清除所有缓存的文件
async function clearCache (db) {
  const transaction = db.transaction('fileCache', 'readwrite');
  transaction.objectStore('fileCache').clear();
  lruCache.clear(); // 清除 LRU 缓存
}

// 检查并清理过期缓存
export async function checkAndClearOldCache () {
  const db = await openDatabase();
  const transaction = db.transaction('metaData', 'readwrite');
  const store = transaction.objectStore('metaData');
  const request = store.get('lastClearDate');

  request.onsuccess = async () => {
    const today = new Date().toISOString().split('T')[0];
    const lastClearDate = request.result ? request.result.date : null;

    if (lastClearDate !== today) {
      await clearCache(db);
      store.put({ key: 'lastClearDate', date: today });
      console.log('缓存已清理');
    }
  };
}

// 跨域请求文件并处理缓存逻辑
export async function fetchAndCacheFile (url, last_modified) {
  const db = await openDatabase();

  // 从缓存中获取文件的 Last-Modified 时间
  const cachedFile = await getFileFromCache(db, url);
  const cachedLastModified = cachedFile ? cachedFile.lastModified : null;

  // 如果缓存中的 Last-Modified 与传入的 last_modified 一致，直接返回缓存数据
  if (cachedLastModified === last_modified && cachedFile && cachedFile.data) {
    //console.log('缓存未变化，直接返回缓存结果');
    return cachedFile.data;
  }

  // 设置请求头，带上 If-Modified-Since
  const headers = cachedLastModified ? { 'If-Modified-Since': cachedLastModified } : {};

  try {
    active_thread+=1;
    console.log('active_thread:', active_thread);
    const response = await fetch(url, {
      method: 'GET',
      headers,
      mode: 'cors'
    });
    active_thread-=1;
    if (response.status === 200) {
      const buffer = await response.arrayBuffer();
      const newLastModified = response.headers.get('Last-Modified');


      // 调用 extract_file 函数解析内容
      const extractedObject = await extract_file(url, buffer);
      if (!extractedObject) {
        return;
      }

      // 存储解析后的对象和新的 Last-Modified 时间
      await storeExtractedObject(db, url, extractedObject, newLastModified);
      console.log('文件已更新并缓存，url:' + url + ' 大小:', buffer.byteLength);
      return extractedObject;
    } else if (response.status === 304 && cachedFile) {
      console.log('文件未更新，使用缓存数据');

      return cachedFile.data;
    } else {
      console.error('Fetch failed:无法获取');
    }
  } catch (error) {
    console.error('Fetch failed:'+url+":"+error.message);
  }
}
