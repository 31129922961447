import { createApp } from 'vue'
import App from './App.vue'
import { connectWebSockets } from './plugins/websocket';
import router from './router'
import ElementPlus from 'element-plus'
import { store } from './plugins/store' // ȷ··ȷ
import 'element-plus/theme-chalk/index.css'
import './assets/main.css' // ȫʽļ
import { serverStore } from '@/config/servers'
import { serverApi } from '@/api/serverApi'
// import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css'

const initializeApp = async () => {
  try {
    // 从 API 加载服务器配置
    const servers = await serverApi.getAllServers();
    console.log('获取到的服务器列表:', servers);
    
    // 清空现有服务器配置
    serverStore.servers = {};
    
    // 更新服务器配置并保存到本地存储
    servers.forEach(server => {
      serverStore.addServer({
        key: server.key,
        type: server.type,
        name: server.name,
        label: server.label,
        apiEndpoint: server.api_endpoint,
        wsEndpoint: server.ws_endpoint,
        order: server.order,
        isDefault: server.isDefault,
        status: server.status,
        refreshTime: server.refresh_time,
        timeDiff: server.time_diff,
        modified: server.modified
      });
    });

    // 创建并挂载应用
    const app = createApp(App)
    app.use(ElementPlus, { size: 'small', zIndex: 3000 })
    app.use(router)
    app.use(store)
    // app.use(Antd)
    connectWebSockets()
    app.mount('#app')
  } catch (error) {
    console.error('初始化失败:', error);
    // 使用本地存储的配置继续启动应用
    serverStore.loadFromStorage();
    const app = createApp(App)
    app.use(ElementPlus, { size: 'small', zIndex: 3000 })
    app.use(router)
    app.use(store)
    // app.use(Antd)
    connectWebSockets()
    app.mount('#app')
  }
}

initializeApp()
