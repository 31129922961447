<template>

  <el-card class="header-card" v-if="totaltask &&totaltask.length>0">
    <div>历史任务</div>
    <TreeViewObj :data="totaltask" :expand="false"/>

  </el-card>
  <el-card class="header-card" v-if="datatask &&datatask.length>0">
    <div>系统任务</div>
    <TreeViewObj :data="datatask" :expand="false"/>

  </el-card>

</template>

<script setup>
import { defineProps, onMounted, onUnmounted, ref } from 'vue';
// import { request_data,wdb_get } from '@/plugins/axios.js';

// import { getDateTimestamp, isToday } from '@/plugins/timetool.js';
import TreeViewObj from '@/common/TreeViewObj.vue';
import { getDateTimestamp } from '@/plugins/timetool';
import { wdb_get_new } from '@/plugins/axios';

// 接收prop
const props = defineProps({
  device: String,
  date: String,
  serverType: String
});

let requesting_task = 0
const totaltask = ref([])
const datatask = ref([])

async function getTasks () {
  if (!props.device || requesting_task) return
  requesting_task = 1
  const time = getDateTimestamp(props.date)
  console.log(props)
  console.log(props.serverType)
  let th = await wdb_get_new(props.serverType, '任务历史', props.device, '当日任务', time)
  console.log(th)
  if (th == null) th = []
  const his_task = []
  const his_data = []
  // console.log("ac", ac, "tc", tc, "detail", detail, "th", th)

  for (const task in th) {
    if (!th[task]) continue
    if (th[task].task === 'datapacker') {
      his_data.unshift(th[task])
    } else {
      his_task.unshift(th[task])
    }
  }

  totaltask.value = his_task
  datatask.value = his_data
  requesting_task = 0
}

let intervalId = null;
onMounted(async () => {
  await getTasks()
  intervalId = setInterval(getTasks, 10000);
});
onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});

</script>
<style scoped>
/* 添加到你的组件的<style>标签内 */
.header-card {
  overflow-y: auto;
  width: 100%;
  font-size: 0.5em; /* 调整为适当的大小，如果0.5em太小 */
}
</style>
