<template>
  <!-- 设备详细数据 -->
  <el-card>
    <div class="header-content sticky-header" v-if="stage !== 2">
      <!-- 日期选择下拉框 -->
      <el-select v-model="selectedReportDate" placeholder="选择报告日期" style="width: 150px;">
        <el-option
            v-for="date in reportDates"
            :key="date"
            :label="date"
            :value="date">
        </el-option>
      </el-select>
      <el-button
          @click="handleClose"
          type="primary"
          v-if="stage === 3">
        返回列表页
      </el-button>
      <el-select placeholder="下载报告" style="margin-left: 10px;width: 100px" v-if="special">
        <el-option @click="downloadExcel('day')" label="下载当日" value="today"></el-option>
        <el-option @click="downloadExcel('history')" label="下载历史" value="all"></el-option>
        <el-option @click="downloadExcel('all')" label="下载当日所有" value="all"></el-option>
      </el-select>
    </div>
    <div class="report-container">
      <h1 class="report-title">睡眠报告</h1>
      <div class="report-section" v-if="isShenzhenHospital!=='1'">
        <h4>用户信息</h4>
        <table class="user-info-table">
          <tr>
            <td> 姓名: {{ userinfo.username }}</td>
            <td> 手机号: {{ userinfo.phoneNumber }}</td>
            <td> 性别: {{ userinfo.sex }}</td>
          </tr>
        </table>
      </div>
      <div class="report-section">
        <h4>睡眠分析</h4>
        <table class="data-table">
          <tr>
            <td>关灯时间，Lights Out Time （睡眠意图开始时刻）</td>
            <td class="right-align">
              <span>{{ formatTime(data.sleep_intention_time) }}</span>
            </td>
          </tr>
          <tr>
            <td>开灯时间，Lights On Time （睡眠意图消失时刻）</td>
            <td class="right-align">
              <span>{{ formatTime(data.intention_leave_time) }}</span>
            </td>
          </tr>
          <tr>
            <td>总睡眠时长,TST</td>
            <td class="right-align">
              <span>{{ formatMinutes(data.tst) }}</span>
            </td>
          </tr>
          <tr>
            <td>睡眠效率,SE</td>
            <td class="right-align">
              <span>{{ getSleepEfficiency(data.tst, data.tib) }}%</span>
            </td>
          </tr>
          <tr>
            <td>睡眠潜伏期,SOL</td>
            <td class="right-align">
              <span>{{ formatMinutes(data.sol) }}</span>

            </td>
          </tr>
          <tr>
            <td>睡眠中断,WASO</td>
            <td class="right-align">
              <span>{{ formatMinutes(data.waso) }}</span>

            </td>
          </tr>
          <tr>
            <td>深睡眠</td>
            <td class="right-align">
              <span>{{ formatMinutes(data.deep_time) }}</span>

            </td>
          </tr>
          <tr>
            <td>快速眼动睡眠潜伏期,Rem Latency</td>
            <td class="right-align">
              <span>{{ formatMinutes(data.rem_latency) }}</span>

            </td>
          </tr>
          <tr>
            <td>睡眠规律性,SRI</td>
            <td class="right-align">
              <span>{{ data.sri !== -1 ? `${Math.round(data.sri)}%` : '-' }}</span>

            </td>
          </tr>
          <tr>
            <td>睡眠碎片化，SFI</td>
            <td class="right-align">
              <span>{{ data.sfi !== -1 ? `${data.sfi}` : '-' }}</span>
            </td>
          </tr>
        </table>
      </div>
      <!--睡眠结构 -->

      <div class="report-section">
        <h4>睡眠结构</h4>
        <div :id="chartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        <div :id="ArtChartId"
             style="width: 80%; height: 80px; background-color: white;margin-top: 5px;margin-bottom: 5px"></div>

        <table class="data-table">
          <tr>
            <td>清醒</td>
            <td>{{ formatMinutes(data.sleep_dur_1) }}</td>
          </tr>
          <tr>
            <td>快速眼动睡眠</td>
            <td>{{ formatMinutes(summmary.rem_sleep_time) }}（{{
                Math.round(parseFloat(summmary.rem_efficiency) * 100)
              }}%）
            </td>
          </tr>
          <tr>
            <td>浅睡眠</td>
            <td>{{
                formatMinutes(summmary.core_sleep_time)
              }}（{{ Math.round(parseFloat(summmary.core_sleep_efficiency) * 100) }}%）
            </td>
          </tr>
          <tr>
            <td>深睡眠</td>
            <td>{{
                formatMinutes(summmary.deep_sleep_time)
              }}（{{ Math.round(parseFloat(summmary.deep_sleep_efficiency) * 100) }}%）
            </td>
          </tr>
        </table>
      </div>
      <!--呼吸健康 -->
      <div class="report-section">
        <h4> 呼吸健康</h4>
        <table class="data-table">
          <tr>
            <td>呼吸暂停低通指数,AHI</td>
            <td>{{ parseFloat(data.ahi).toFixed(1) }}</td>
          </tr>
          <tr>
            <td>NREM AHI</td>
            <td>{{ parseFloat(data.ahi_345).toFixed(1) }}</td>
          </tr>
          <tr>
            <td>REM AHI</td>
            <td>{{ parseFloat(data.ahi_2).toFixed(1) }}</td>
          </tr>
          <tr>
            <td>鼾声指数,SI</td>
            <td>{{ parseFloat(data.bcgsnore_index).toFixed(1) }}</td>
          </tr>
          <tr>
            <td>睡眠平均呼吸率</td>
            <td>{{ Math.round(parseFloat(data.avg_resp)).toFixed(1) }}次/分</td>
          </tr>
        </table>

        <div :id="AhChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px">
        </div>
        <div class="report-section">
          <h4>鼾声分析</h4>

          <div :id="SnoreChartId"
               style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>

        </div>
        <!--睡眠生理指标 -->
        <div class="report-section">
          <h4> 睡眠期生理指标</h4>
          <table class="data-table">
            <tr>
              <td>睡眠最低心率</td>
              <td>{{ summmary.min_sleep_heart_rate }}次/分</td>
            </tr>
            <tr>
              <td>睡眠平均心率</td>
              <td>{{ summmary.avg_sleep_heart_rate }}次/分</td>
            </tr>
            <tr>
              <td>睡眠期HRV_RMSSD</td>
              <td>{{ summmary.avg_hrv_rmssd }}毫秒</td>
            </tr>
            <tr>
              <td>睡眠期HRV_LF/HF</td>
              <td>{{ summmary.avg_hrv_radio }}</td>
            </tr>


          </table>
          <h5>睡眠心率</h5>

          <div :id="HrChartId"
               style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
          <h5>睡眠心率变异性</h5>


          <div :id="HrvChartId"
               style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
          <h5>睡眠呼吸频率</h5>

        </div>

        <div :id="RrChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
      </div>
      <h5>血氧</h5>

      <div :id="spo2ChartId"
           style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px;margin-left: -2%">
      </div>
      <!--睡眠环境 -->

      <div class="report-section">
        <h4>睡眠环境</h4>
        <table class="data-table">
          <tr>
            <td>平均温度</td>
            <td>{{ Math.round(parseFloat(endData.平均温度)) }}°C</td>
          </tr>
          <tr>
            <td>平均湿度</td>
            <td>{{ Math.round(parseFloat(endData.平均湿度)) }}%</td>
          </tr>
          <tr>
            <td>光照影响的睡眠比例</td>
            <td>{{ Math.round(parseFloat(summmary.high_illuminance) * 100) }}%</td>
          </tr>
        </table>
        <div :id="EnvChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>

      </div>
      <div class="report-section">
        <h4>月趋势</h4>
        <h5>总睡眠时长</h5>
        <div :id="TstChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>


        <h5>入睡速度</h5>

        <div :id="solChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        <h5>睡眠规律性</h5>

        <div :id=" sriChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        <h5>睡眠中断时长</h5>

        <div :id="wasoChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        <h5>睡眠效率</h5>

        <div :id="seChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        <h5>快速眼动和深睡时长</h5>

        <div :id=" deepChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        <h5>各睡眠阶段的时长</h5>

        <div :id="StageCompChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        <h5>呼吸暂停低通气指数(AHI)</h5>

        <div :id="ahiChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px;margin-left: -2%">
        </div>
        <h5>鼾声指数</h5>

        <div :id="SnoreIndexChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        <h5>睡眠心率变异性</h5>

        <div :id="hrvBLChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
      </div>
      <h5>睡眠呼吸频率</h5>

      <div :id="rrBLChartId"
           style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>

      <h5>睡眠心率</h5>
      <div :id="hrBLChartId"
           style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
      <h5>睡眠环境</h5>

      <div :id="envChartId"
           style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
    </div>

  </el-card>
</template>


<script setup>

import {defineProps, ref, watch, defineEmits, onMounted} from "vue";
import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import {createAxiosInstance, request_data} from "@/plugins/axios";
import * as echarts from "echarts";
import {setStageChartOption} from "@/plugins/chart/stage_chart";
import {setArtChartOption} from "@/plugins/chart/day_art_chart";
import {setArt2ChartOption} from "@/plugins/chart/day_art_chart2";
import {setDayEnvChartOption} from "@/plugins/chart/day_env_chart";
import {setDayHRVChartOption} from "@/plugins/chart/day_hrv_chart";
import {setDayRRChartOption} from "@/plugins/chart/day_rr_chart";
import {setStageAHChartOption} from "@/plugins/chart/stage_ah_chart";
import {setDayHRChartOption} from "@/plugins/chart/day_hr_chart";
import {setStageAH2ChartOption} from "@/plugins/chart/stage_ah_chart2";
// import {setRRBaselineChartOption} from "@/plugins/chart/rr_baseline_chart";
// import {setHRBaselineChartOption} from "@/plugins/chart/hr_baseline_chart";
// import {setHRVBaselineChartOption} from "@/plugins/chart/hrv_baseline_chart";
// import {setAHIChartOption} from "@/plugins/chart/ahi_color_chart";
import {chart_dates, resetDates} from "@/plugins/chart/variables";
import {formatDateString} from "@/plugins/utils/utils";
import {setSpO2ChartOption} from "@/plugins/chart/spo2_chart";
import {setDaySnoreChartOption} from "@/plugins/chart/day_snore_chart";
import {setTSTChartOption} from "@/plugins/chart/tst_chart";
import {setStageCompChartOption} from "@/plugins/chart/stage_comp_chart";
import {setSnoreIndexChartOption} from "@/plugins/chart/snore_index_chart";
import {setSOLChartOption} from "@/plugins/chart/sol_chart";
import {setWASOChartOption} from "@/plugins/chart/waso";
import {setDeepChartOption} from "@/plugins/chart/deep_time_chart";
import {setEnvChartOption} from "@/plugins/chart/env_chart";
import {setSEChartOption} from "@/plugins/chart/tst_chart";
import {setTIBChartOption} from "@/plugins/chart/tib_chart";
import {setHRVChartOption} from "@/plugins/chart/hrv_chart";
import {setRRChartOption} from "@/plugins/chart/rr_chart";
import {setHRChartOption} from "@/plugins/chart/hr_chart";
// import {setAHI2345ChartOption} from "@/plugins/chart/ahi_month_chart";
import {setAHIChartOption} from "@/plugins/chart/ahi_month_chart";

const emit = defineEmits(["update:device"])

const props = defineProps({
  device: String,
  selections: Array,
  date: String,
  stage: String,
  special: String,
  isShenzhenHospital: String

});
const stage = ref(props.stage)
const selectedDevice = ref(props.device)
const selections = ref(props.selections)
const isShenzhenHospital = ref(props.isShenzhenHospital)
const userinfo = ref(selections.value['userinfo'])
const deviceinfo = ref(selections.value['deviceinfo'])
const selectedReportDate = ref(props.date); // 选中的报告日期
const reports = ref({}); // 报告列表
const reportDates = ref([]); // 报告日期列表
const keyMap = {
  reporttime: "报告开始计算时间",
  costtime: "报告生成总耗时",
  startc: "数据开始时间",
  endc: "数据结束时间",
  dur: "数据总时长",
  snore_index: "打鼾指数",
  snore_index_onback: "仰卧打鼾指数",
  snore_index_onside: "侧卧打鼾指数",
  posion_onback_time: "仰卧时长",
  posion_onback_rate: "仰卧占比",
  posion_onside_time: "侧卧时长",
  posion_onside_rate: "侧卧占比",

  sigtype_0: "无信号",
  sigtype_1: "低信号",
  sigtype_2: "正常信号",
  sigtype_3: "微体动信号",
  sigtype_4: "强体动信号",
  onbedlevel: "在床阈值",
  err_log: "错误信息",
  avg_temp: "平均温度",
  avg_humi: "平均湿度",
  avg_illu: "睡眠时平均光照",
  high_illu: "强光照睡眠",
  sleep_time: "有效睡眠总时间",
  ahi_count: "呼吸事件总数",
  ahi: "综合AHI",
  long_artifact: "体动次数",
  short_artifact: "微体动次数",
  sri: "SRI",
  quality: "睡眠质量分",
  qualitylogs: "睡眠质量细则",
  sleep_dur_0: "睡眠分期_体动和无效时长",
  ahi_count_0: "体动呼吸事件次数（无效）",
  sleep_dur_1: "睡眠分期_Wake时长",
  ahi_count_1: "Wake呼吸事件次数（无效）",
  sleep_dur_2: "睡眠分期_REM时长",
  ahi_count_2: "REM期呼吸事件次数",
  ahi_2: "REM期呼吸指数",
  sleep_dur_3: "睡眠分期_N1时长",
  sleep_dur_4: "睡眠分期_N2时长",
  sleep_dur_5: "睡眠分期_N3时长",
  ahi_count_345: "NREM期呼吸事件次数",
  ahi_345: "NREM期呼吸指数",
  odi_count: "ODI次数",
  odi: "ODI指数",
  min_spo2: "最低血氧饱和度",
  mean_spo2: "平均血氧饱和度",
  hrv_rmssd_mean: "hrv_rmssd",
  hrv_morning: "hrv_morning",
  hrv_evening: "hrv_evening",
  hrv_delta: "hrv_delta",
  hrv_ratio_mean: "hrv_ratio",
  sleep_range: "睡眠区间调试信息",
  push_message: "push消息",
};
const endData = ref({})
const data = ref({})
const summmary = ref({})
const stage_data = {}
const art_data = {}
const env_data = {}
const hrv_data = {}
const rr_data = {}
const hr_data = {}
const spo2_data = {}
const snore_data = {}
const chartId = ref(`stage_chart_${Math.random().toString(36).substr(2, 9)}`);
const ArtChartId = ref(`art_chart_${Math.random().toString(36).substr(2, 9)}`);
const EnvChartId = ref(`env_chart_${Math.random().toString(36).substr(2, 9)}`);
const HrvChartId = ref(`hrv_chart_${Math.random().toString(36).substr(2, 9)}`);
const RrChartId = ref(`rr_chart_${Math.random().toString(36).substr(2, 9)}`);
const AhChartId = ref(`ah_chart_${Math.random().toString(36).substr(2, 9)}`);
const HrChartId = ref(`hr_chart_${Math.random().toString(36).substr(2, 9)}`);
const rrBLChartId = ref(`rr_bl_chart_${Math.random().toString(36).substr(2, 9)}`);
const hrBLChartId = ref(`hr_bl_chart_${Math.random().toString(36).substr(2, 9)}`);
const hrvBLChartId = ref(`hrv_bl_chart_${Math.random().toString(36).substr(2, 9)}`);
const ahiChartId = ref(`ahi_chart_${Math.random().toString(36).substr(2, 9)}`);
const spo2ChartId = ref(`spo2_chart_${Math.random().toString(36).substr(2, 9)}`);
const SnoreChartId = ref(`snore_chart_${Math.random().toString(36).substr(2, 9)}`);
const TstChartId = ref(`tst_chart_${Math.random().toString(36).substr(2, 9)}`);
const StageCompChartId = ref(`stage_comp_chart_${Math.random().toString(36).substr(2, 9)}`);
const SnoreIndexChartId = ref(`snore_index_chart_${Math.random().toString(36).substr(2, 9)}`);
const solChartId = ref(`sol_chart_${Math.random().toString(36).substr(2, 9)}`);
const wasoChartId = ref(`waso_chart_${Math.random().toString(36).substr(2, 9)}`);
const seChartId = ref(`se_chart_${Math.random().toString(36).substr(2, 9)}`);
const envChartId = ref(`env_month_chart_${Math.random().toString(36).substr(2, 9)}`);
const deepChartId = ref(`deep_chart_${Math.random().toString(36).substr(2, 9)}`);
const sriChartId = ref(`sri_chart_${Math.random().toString(36).substr(2, 9)}`);
const reports_data = ref([])

//计算总时长
function formatMinutes(minutes) {
  if (Number.isFinite(minutes)) {  // 检查minutes是否为一个有限的数
    const roundedMinutes = Math.round(minutes);
    const hours = Math.floor(roundedMinutes / 60);
    const remainingMinutes = roundedMinutes % 60;

    if (hours === 0) {
      return `${remainingMinutes} 分钟`;
    }

    return `${hours} 时 ${remainingMinutes} 分`;
  }
  return '';
}

const formatTime = (timestamp) => {
  if (!timestamp) return '--:--';

  try {
    const date = new Date(timestamp * 1000);

    // 检查是否是有效日期
    if (isNaN(date.getTime())) {
      return '--:--';
    }

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  } catch (error) {
    console.error('时间格式化错误:', error);
    return '--:--';
  }
}

//更新数据
function updateEndData() {
  const mappedData = {};
  data.value = reports.value['online']
  // 分配映射的数据
  if (data.value) {
    Object.keys(keyMap).forEach(key => {
      if (key in data.value) {
        mappedData[keyMap[key]] = data.value[key];
      }
    })
  }
  // 添加'其他'分类
  endData.value = mappedData;
}

//监听日期 选择的被试
watch([() => selectedReportDate.value, () => selectedDevice.value], async ([newDate, newDevice], [oldDate, oldDevice]) => {
  if (newDevice == "" || newDevice != oldDevice) {
    emit('update:device', newDevice); // 确保这段代码在正确的上下文中
  } else if (oldDate !== newDate) {
    getReports()
    doRender()

  }
}, {immediate: true});
//监听日期
watch(() => props.date, (newVal) => {
  // 当数据变化时，重新渲染图表
  selectedReportDate.value = newVal

  getReports()
  doRender()

}, {deep: true});
watch(() => props.device, (newVal) => {
  // 当数据变化时，重新渲染图表
  selectedDevice.value = newVal
  changeDate()
  getReports()
  doRender()
}, {deep: true});
watch(() => props.selections, (newVal) => {
  // 当数据变化时，重新渲染图表
  selections.value = newVal
  userinfo.value = selections.value['userinfo']
  deviceinfo.value = selections.value['deviceinfo']
  getReports()
  doRender()
}, {deep: true});


async function getReportList(reportdate) {
  try {
    // 确保 reportdate 是有效的日期字符串或时间戳
    if (!reportdate) {
      console.error('Report date is required');
      return;
    }

    // 将 reportdate 转换为 Date 对象并验证
    let selectedDate = new Date(reportdate);
    if (isNaN(selectedDate.getTime())) {
      console.error('Invalid report date:', reportdate);
      return;
    }

    // 计算起始日期和结束日期
    let endDate = new Date(selectedDate);
    let startDate = new Date(selectedDate);
    startDate.setDate(selectedDate.getDate() - 29);

    // 验证日期范围
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error('Invalid date range');
      return;
    }

    // 格式化日期为 yyyy-mm-dd
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    let formattedStartDate = formatDate(startDate);
    let formattedEndDate = formatDate(endDate);

    // 验证 selectedDevice
    if (!selectedDevice?.value) {
      console.error('Selected device is required');
      return;
    }

    // 发送请求
    let response = await request_data(
      `/deviceallreport/${selectedDevice.value}/${formattedStartDate}/${formattedEndDate}/`,
    );

    // 验证响应数据
    if (response) {
      reports_data.value = response;
    } else {
      console.error('No response data received');
    }
  } catch (error) {
    console.error('Error in getReportList:', error);
    // 这里可以添加错误处理逻辑，比如显示错误消息给用户
  }
}



//获取 report 数据；画图
async function getReports() {
  if (!props.device) return
  const reportdate = selectedReportDate.value ? selectedReportDate.value : "_";
  reports.value = await request_data(`/devicereport/${selectedDevice.value}/${reportdate}/`);
  await getReportList(selectedReportDate.value)

  if (reports.value['summary']) {
    summmary.value = reports.value['summary']
    updateEndData()
    doRender()
    doAhChart()
    doArtChart()
    doEnvChart()
    doHrvChart()
    doRrChart()
    doHrChart()
    doRrBlChart()
    doHrBlChart()
    doHrvBlChart()
    doAhiChart()
    doSpo2Chart()
    doSnoreChart()
    doTstChart()
    doStageCompChart()
    doSnoreIndexChart()
    doSolChart()
    doWasoChart()
    doDeepChart()
    doEnvMonthChart()
    doSeChart()
    doSriChart()
  }

}

//返回列表
const handleClose = () => {
  selectedDevice.value = ''
};

async function changeDate() {
  reportDates.value = []
  selectedReportDate.value = ""
  const response = await request_data(`/list_reports/${selectedDevice.value}/`);
  reportDates.value = response.nodes;
  if (response.nodes.length > 0 && selectedReportDate.value === '') {
    selectedReportDate.value = response.nodes[0];
    getReports()

  }
  return response
}

//画图
function doRender() {
  stage_data.start = data.value['starttime'];  //starttime 字段
  stage_data.end = data.value['endtime'];  //endtime 字段
  stage_data.stages = data.value['stage_details']// stage_details
  stage_data.nointention = (data.value['intention_middle'] && data.value['intention_middle'].length > 0) ? data.value['intention_middle'] : null
  stage_data.leavebed = (data.value['leavebed'] && data.value['leavebed'].length > 0) ? data.value['leavebed'] : null
  const canvas = document.getElementById(chartId.value)
  const chart = echarts.init(canvas);
  setStageChartOption(chart, stage_data);
  return chart;
}

//体动
function doArtChart() {
  const art_canvas = document.getElementById(ArtChartId.value)
  const art_chart = echarts.init(art_canvas);
  art_data.start = data.value['starttime'];  //starttime 字段
  art_data.end = data.value['endtime'];  //endtime 字段
  art_data.leavebed = (data.value['leavebed'] && data.value['leavebed'].length > 0) ? data.value['leavebed'] : null

  if (data.value['artifact_series']) {
    art_data.segments = data.value['artifact_series']
    art_data.stages = data.value['stage_details']// stage_details
    setArt2ChartOption(art_chart, art_data)

  } else {
    art_data.segments = data.value['artifact_events']
    setArtChartOption(art_chart, art_data);

  }

  return art_chart;
}

//鼾声
function doSnoreChart() {
  const snore_canvas = document.getElementById(SnoreChartId.value)
  const snore_chart = echarts.init(snore_canvas);
  snore_data.start = data.value['starttime'];  //starttime 字段
  snore_data.end = data.value['endtime'];  //endtime 字段
  snore_data.stage = data.value['stage_details']
  snore_data.snore = data.value['bcgsnore_events']
  setDaySnoreChartOption(snore_chart, snore_data)

  return snore_chart;
}


//环境
function doEnvChart() {
  env_data.start = data.value['starttime'];  //starttime 字段
  env_data.end = data.value['endtime'];  //endtime 字段
  // env_data.intention = data.value['sleep_intention']// stage_details
  env_data.nointention = data.value['intention_middle']// stage_details
  env_data.leavebed = data.value['leavebed']
  // env_data.leave = data.value['sleep_leave']
  env_data.segments = data.value['thi_series']
  const env_canvas = document.getElementById(EnvChartId.value)
  const env_chart = echarts.init(env_canvas);
  setDayEnvChartOption(env_chart, env_data);
  return env_chart;
}
//血氧
function doSpo2Chart() {
  spo2_data.start = data.value['starttime'];  //starttime 字段
  spo2_data.end = data.value['endtime'];  //endtime 字段
  spo2_data.stage = data.value['stage_details']// stage_details
  spo2_data.spo2 = data.value['spo2_data']

  const spo2_canvas = document.getElementById(spo2ChartId.value)
  const spo2_chart = echarts.init(spo2_canvas);
  setSpO2ChartOption(spo2_chart, spo2_data);
  return spo2_chart;
}

function doHrvChart() {
  hrv_data.start = data.value['starttime'];  //starttime 字段
  hrv_data.end = data.value['endtime'];  //endtime 字段
  hrv_data.nointention = data.value['intention_middle']
  hrv_data.leavebed = data.value['leavebed']
  // hrv_data.intention = data.value['sleep_intention']
  // hrv_data.leave = data.value['sleep_leave']
  hrv_data.segments = data.value['hrv_rmssd']
  hrv_data.avg = data.value['hrv_rmssd_mean']
  const hrv_canvas = document.getElementById(HrvChartId.value)
  const hrv_chart = echarts.init(hrv_canvas);
  setDayHRVChartOption(hrv_chart, hrv_data);
  return hrv_chart;
}

function doRrChart() {
  rr_data.start = data.value['starttime'];  //starttime 字段
  rr_data.end = data.value['endtime'];  //endtime 字段
  // rr_data.intention = data.value['sleep_intention']// stage_details
  rr_data.nointention = data.value['intention_middle']// stage_details
  // rr_data.leave = data.value['sleep_leave']
  rr_data.leavebed = data.value['leavebed']
  rr_data.segments = data.value['resp_series']
  rr_data.avg = data.value['avg_resp']
  const rr_canvas = document.getElementById(RrChartId.value)
  const rr_chart = echarts.init(rr_canvas);
  setDayRRChartOption(rr_chart, rr_data);
  return rr_chart;
}

//呼吸
function doAhChart() {
  const ah_data = {}
  const ah_canvas = document.getElementById(AhChartId.value)
  const ah_chart = echarts.init(ah_canvas);
  ah_data.nointention = (data.value['intention_middle'] && data.value['intention_middle'].length > 0) ? data.value['intention_middle'] : null

  ah_data.start = data.value['starttime'];  //starttime 字段
  ah_data.end = data.value['endtime'];  //endtime 字段
  ah_data.leavebed = (data.value['leavebed'] && data.value['leavebed'].length > 0) ? data.value['leavebed'] : null
  ah_data.stages = data.value['stage_details']

  if (data.value['ahi_series']) {
    ah_data.ah = data.value['ahi_series']
    setStageAH2ChartOption(ah_chart, ah_data);

  } else {
    ah_data.ah = data.value['ahi_events']
    setStageAHChartOption(ah_chart, ah_data);

  }


  return ah_chart;
}

//心率
function doHrChart() {
  hr_data.start = data.value['starttime'];  //starttime 字段
  hr_data.end = data.value['endtime'];  //endtime 字段
  hr_data.nointention = (data.value['intention_middle'] && data.value['intention_middle'].length > 0) ? data.value['intention_middle'] : null
  hr_data.leavebed = data.value['leavebed']
  hr_data.avg = data.value['bpm_avg']
  hr_data.segments = data.value['bpm_series']
  const hr_canvas = document.getElementById(HrChartId.value)
  const hr_chart = echarts.init(hr_canvas);
  setDayHRChartOption(hr_chart, hr_data);
  return hr_chart;
}

//平均呼吸率图表
function doRrBlChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const rr_bl_canvas = document.getElementById(rrBLChartId.value)
  const rr_bl_chart = echarts.init(rr_bl_canvas);
  // initChartDates(reports_data.value, selectedReportDate.value)
  parseReportsToDisplay(reports_data.value)
  setRRChartOption(reports_data.value, rr_bl_chart, chart_dates);
  return rr_bl_chart;
}

//最低心率趋势
function doHrBlChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const hr_bl_canvas = document.getElementById(hrBLChartId.value)
  const hr_bl_chart = echarts.init(hr_bl_canvas);
  parseReportsToDisplay(reports_data.value)
  setHRChartOption(reports_data.value, hr_bl_chart, chart_dates);
  return hr_bl_chart;
}

//hrv rmssd 趋势
// function doHrvBlChart() {
//   // eslint-disable-next-line no-unused-vars
//   const {chart_dates, resetDates} = import('../plugins/chart/variables');
//   const hrv_bl_canvas = document.getElementById(hrvBLChartId.value)
//   const hrv_bl_chart = echarts.init(hrv_bl_canvas);
//   initChartDates(reports_data.value, selectedReportDate.value)
//   setHRVBaselineChartOption(reports_data.value, hrv_bl_chart, chart_dates);
//   return hrv_bl_chart;
// }
function doHrvBlChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const hrv_bl_canvas = document.getElementById(hrvBLChartId.value)
  const hrv_bl_chart = echarts.init(hrv_bl_canvas);
  initChartDates(reports_data.value, selectedReportDate.value)
  setHRVChartOption(reports_data.value, hrv_bl_chart, chart_dates);
  return hrv_bl_chart;
}

//ahi 趋势
function doAhiChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const ahi_canvas = document.getElementById(ahiChartId.value)
  const ahi_chart = echarts.init(ahi_canvas);
  parseReportsToDisplay(reports_data.value)

  setAHIChartOption(reports_data.value, ahi_chart, chart_dates);
  return ahi_chart;
}

//ahi 趋势
function doTstChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const tst_canvas = document.getElementById(TstChartId.value)
  const tst_chart = echarts.init(tst_canvas);
  parseReportsToDisplay(reports_data.value)
  setTSTChartOption(reports_data.value, tst_chart, chart_dates);
  return tst_chart;
}

function doStageCompChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const stage_canvas = document.getElementById(StageCompChartId.value)
  const stage_chart = echarts.init(stage_canvas);
  parseReportsToDisplay(reports_data.value)
  setStageCompChartOption(reports_data.value, stage_chart, chart_dates);
  return stage_chart;
}

//鼾声趋势
function doSnoreIndexChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const snore_index_canvas = document.getElementById(SnoreIndexChartId.value)
  const snore_index_chart = echarts.init(snore_index_canvas);
  parseReportsToDisplay(reports_data.value)
  setSnoreIndexChartOption(reports_data.value, snore_index_chart, chart_dates);
  return snore_index_chart;
}

//睡眠效率 sol
function doSolChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const sol_canvas = document.getElementById(solChartId.value)
  const sol_chart = echarts.init(sol_canvas);
  parseReportsToDisplay(reports_data.value)
  setSOLChartOption(reports_data.value, sol_chart, chart_dates);
  return sol_chart;
}

//睡眠中断时间趋势
function doWasoChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const waso_canvas = document.getElementById(wasoChartId.value)
  const waso_chart = echarts.init(waso_canvas);
  parseReportsToDisplay(reports_data.value)
  setWASOChartOption(reports_data.value, waso_chart, chart_dates);
  return waso_chart;
}

function doDeepChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const deep_canvas = document.getElementById(deepChartId.value)
  const deep_chart = echarts.init(deep_canvas);
  parseReportsToDisplay(reports_data.value)
  setDeepChartOption(reports_data.value, deep_chart, chart_dates);
  return deep_chart;
}

function doSeChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const se_canvas = document.getElementById(seChartId.value)
  const se_chart = echarts.init(se_canvas);
  parseReportsToDisplay(reports_data.value)
  setSEChartOption(reports_data.value, se_chart, chart_dates);
  return se_chart;
}

//睡眠环境
function doEnvMonthChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const env_month_canvas = document.getElementById(envChartId.value)
  const env_month_chart = echarts.init(env_month_canvas);
  parseReportsToDisplay(reports_data.value)
  setEnvChartOption(reports_data.value, env_month_chart, chart_dates);
  return env_month_chart;
}

//睡眠规律性
function doSriChart() {
  // eslint-disable-next-line no-unused-vars
  const {chart_dates, resetDates} = import('../plugins/chart/variables');
  const sri_canvas = document.getElementById(sriChartId.value)
  const sri_chart = echarts.init(sri_canvas);
  parseReportsToDisplay(reports_data.value)
  setTIBChartOption(reports_data.value, sri_chart, chart_dates);
  return sri_chart;
}

// 初始化日期（横轴）
function initChartDates(reports, reportKey = '') {
  if (!reports) return;

  const max_chart_days = 30;
  let today;

  try {
    if (reportKey) {
      today = new Date(reportKey);
      if (isNaN(today.getTime())) {
        throw new Error('Invalid date');
      }
    } else {
      today = new Date();
    }

    today.setHours(0, 0, 0, 0);
    let todayTS = today.getTime() / 1000;
    resetDates();

    for (let i = 0; i < max_chart_days; i++) {
      const shortDate = formatDateString(todayTS * 1000, 'MMdd', false);
      chart_dates[shortDate] = -1;
      todayTS -= 24 * 3600;
    }
  } catch (error) {
    console.error('Error initializing chart dates:', error);
  }
}


const parseReportsToDisplay = (reports) => {
  //console.log("History:parseReportsToDisplay");

  // 传入了报告列表，是长时间趋势图模式
  var i;
  if (reports && reports.length > 0) {
    this.setData({
      reportCount: reports ? reports.length : 0,
    });
    let max_chart_days = 0
    if (this.data.period === 'week') {
      max_chart_days = 7;
    } else if (this.data.period === 'month') {
      max_chart_days = 30;
    } else if (this.data.period === 'year') {
      max_chart_days = 365;
    }

    //生成空的日期序列，用于各图表的日期轴
    var today = new Date(reports[0].end * 1000); //TODO：注意：这里使用第一份有效报告作为横轴最新节点，可能会导致跟取数据库的时间区间对不齐
    today.setHours(0, 0, 0, 0);
    var todayTS = today.getTime() / 1000; //按今天零点对齐
    resetDates(); //先清空
    for (i = 0; i < max_chart_days; i++) {
      var shortDate = formatDateString(todayTS * 1000, "yyyyMMdd", false);
      chart_dates[shortDate] = -1;
      todayTS -= 24 * 3600;
    }
    //在空日期序列中填充有效报告数据的索引
    todayTS = today.getTime() / 1000; //重新按今天零点对齐
    reports.forEach((value, index, array) => {
      var shortDate = formatDateString(value.end * 1000, "yyyyMMdd", false);
      if (chart_dates[shortDate] === -1) {
        chart_dates[shortDate] = index;
      } else if (chart_dates[shortDate] >= 0) {
        let idx = chart_dates[shortDate];
        if (array[idx].report_time < value.report_time) {
          chart_dates[shortDate] = index;
        }
      }
    });
    //去掉日期序列中开头的空白段
    var keysDate = Object.keys(chart_dates).reverse();
    for (i = 0; i < keysDate.length; i++) {
      if (chart_dates[keysDate[i]] >= 0) {
        break;
      } else if (i < keysDate.length - 29) { // 保留最后 14 天，哪怕空白，为了图表不要太短
        delete chart_dates[keysDate[i]];
      }
    }
    //console.log(chart_dates);

  }
}


const
    getSleepEfficiency = (tst, tib) => {
      return Math.round((tst / tib) * 100);
    };


const downloadExcel = async (type) => {
  let days = selectedReportDate.value
  let device = selectedDevice.value
  if (type === 'history') {
    days = 'history'
  } else if (type === 'all') {
    device = 'all'
  }
  const axiosInstance = createAxiosInstance();
  const request_data = await axiosInstance.get(`/report/download/${device}/${days}/`);
  const data = request_data.data.data
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('睡眠报告');

  // 添加表头
  worksheet.addRow([
    '设备',
    '日期', '总睡眠时长', '入睡速度', '睡眠中断', '深睡时间', '快速眼动睡眠时间', '睡眠规律性', '睡眠片段化指数', '清醒',
    'AHI', 'NREM', 'REM', '睡眠平均呼吸率',
    '快速眼动睡眠时间', '快速眼动睡眠效率', '核心睡眠时间', '核心睡眠效率', '深睡时间', '深睡效率',
    '最低睡眠心率', '平均睡眠心率', '睡眠期HRV_RMSSD', '睡眠期HRV_LF/HF', '光照影响的睡眠比例', '平均温度', '平均湿度'
  ]);
  // 添加数据
  data.forEach(entry => {
    const date = entry.date;
    const device = entry.device;
    const online = entry.reports.online;
    const summary = entry.reports.summmary;
    worksheet.addRow([
      device,
      date,
      online.tst, online.sol, online.waso, online.deep_time, online.rem_time, online.sri, online.sfi, online.sleep_dur_1,
      online.ahi, online.ahi_345, online.ahi_2, online.avg_resp,
      summary.rem_sleep_time, summary.rem_efficiency, summary.core_sleep_time, summary.core_sleep_efficiency, summary.deep_sleep_time, summary.deep_sleep_efficiency,
      summary.min_sleep_heart_rate, summary.avg_sleep_heart_rate, summary.avg_hrv_rmssd, summary.avg_hrv_radio, summary.high_illuminance, summary.avg_temperature, summary.avg_humidity
    ]);
  });


  // 生成Excel文件并触发下载
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {type: 'application/octet-stream'});
  if (deviceinfo.value.bednumber) {
    saveAs(blob, `睡眠报告-${deviceinfo.value.bednumber}号床-${selectedDevice.value}-${selectedReportDate.value}.xlsx`);

  } else {
    saveAs(blob, `睡眠报告-${deviceinfo.value.info}-${selectedDevice.value}-${selectedReportDate.value}.xlsx`);

  }
};
onMounted(() => {
  changeDate()
});
</script>


<style scoped>
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px; /* 如果需要按钮和选择框之间增加间隙 */
}


.data-table {
  width: 80%;
  border-collapse: collapse;
}

.data-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: left;
}

.data-table strong {
  font-weight: bold;
}

/* 设置第一列的宽度 */
.data-table td:first-child {
  width: 50%; /* 你可以根据需要调整这个值 */
}


.user-info-table {
  width: 100%;
  border-collapse: collapse;
}

.user-info-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: left;
}

.user-info-table strong {
  font-weight: bold;
}


.report-time td {
  border: 1px solid #ddd;
  padding: 8px;
}

.report-container {
  max-width: 210mm; /* A4纸宽度 */
  min-height: 297mm; /* A4纸高度 */
  margin: 0 auto; /* 居中 */
  padding: 10px; /* 内边距 */
  box-sizing: border-box;
  background: #fff;
  font-family: 'Arial', sans-serif;
  font-size: 12px;

}

.report-title {
  margin-top: -5%;
  text-align: center;
  color: #333;
  font-size: 15px;
}

.header-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -1%;

}


.report-section h4 {
  color: black;
  font-size: 12px;
}

p {
  color: #666;
  line-height: 1.5;
  font-size: 5px;
}

strong {
  font-weight: bold;
}

.report-section div {
  width: 100%;
  box-sizing: border-box;
}

.data-table td.right-align {
  text-align: right;
}

@media print {
  @page {
    size: A4;
    margin: 20mm;
    counter-increment: page;
  }

  .report-container {
    page-break-inside: avoid;
  }

  .report-section {
    page-break-inside: avoid;
  }

  .report-section h4,
  .report-section div,
  .report-time,
  .grid,
  .grid_0 {
    page-break-inside: avoid;
  }

  .page-footer::after {
    content: "Page " counter(page);
  }
}


</style>
