<!-- 文件名: UploadExcel.vue -->

<template>
  <el-upload
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      accept=".xlsx"
      :headers="headers"
      :http-request="customUpload"
  >
    <template v-slot:trigger>
      <el-button type="primary">选择Excel文件</el-button>
    </template>

  </el-upload>
</template>

<script setup>
import {onMounted, defineProps, defineEmits} from 'vue';
import {ElMessage} from 'element-plus';
import {createAxiosInstance} from "@/plugins/axios";


const props = defineProps({
  headers: Object,
  onSuccess: Function,
  onError: Function,
  httpRequest: Function,
  uploadPath: String,
  serverType: String
});

const emit = defineEmits(['success', 'error']);

const customUpload = async (file) => {
  // 创建FormData对象用于构建表单数据
  let formData = new FormData();
  formData.append('file', file.file); // 'file'是后端期待的字段名

  try {
    // 使用axios发送POST请求
    const axiosInstance = createAxiosInstance('收集');
    const response = await axiosInstance.post(props.uploadPath, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 在这里添加任何其他需要的请求头
      },
    });
    emit('success', response);
  } catch (error) {
    emit('error', error);
  }
};

const handleUploadSuccess = (response) => {
  console.log(response)
  ElMessage({
    message: '上传成功',
    type: 'success',
  });
};

const handleUploadError = () => {
  ElMessage({
    message: '上传失败',
    type: 'error',
  });
};

onMounted(() => {
  // 初始化或其他必要操作
});
</script>

<style scoped>
/* 样式代码 */
</style>
