<template>
  <div class="container">
    <div class="filter-select">
      <CustomFilter v-if="user.isShenzhenHospital" item="org1" @filter-changed="handleFilterChange"/>
      <CustomFilter v-else item="org" @filter-changed="handleFilterChange"/>
    </div>
    <div class="content">
      <div class="ort-data-container" v-if="user.isShenzhenHospital">
        <el-row :gutter="20">
          <el-col :span="6" v-for="(row, index) in rows.slice(0, 100)" :key="index" class="card-column"
                  @click="handleCardClick(index, row)">
            <el-card :class="['custom-card', getCardClass(row), { 'selected-card': selectedCard === index }]">
              <div class="stat-item">
                <h2>{{ row.deviceinfo.bednumber }}</h2>
                <p>
                  报告<span v-if="row.sleep_status.sleepreport_state === 1">已生成</span><span v-else>未生成</span>
                </p>
                <p>
                  设备<span v-if="row['设备和网络']['在线']">在线</span><span
                    v-else>离线</span>
                </p>
                <p v-if="row.sleep_result.avg_ahi !== undefined">
                  OSA程度:<span :style="getSpanStyle(getOsaLevel(row.sleep_result.avg_ahi).color)">{{
                    getOsaLevel(row.sleep_result.avg_ahi).level
                  }}</span>
                </p>
                <p v-else>OSA程度:</p>
                <p>{{ row.device }}</p>
              </div>
              <div class="button-container">
                <el-button type="primary" size="small" style="width: 100%; margin-left: 10px"
                           @click.stop="openEditDialog(row)">修改信息
                </el-button>
                <el-button type="primary" size="small" style="width: 100%;" @click="openReportDialog(row)">生成报告
                </el-button>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div v-else class="data-container">
        <el-table :data="rows" table-layout="auto" height="100%"
                  style="width: 100%; margin-top: 20px;cursor: default !important;  box-shadow: none !important;"
                  highlight-current-row @current-change="handleRowClick" :current-row="currentRow">
          <el-table-column type="selection" width="30"/>
          <el-table-column prop="device" label="编号">
            <template #default="scope">
              <div style="width: 100% ;height: 100% ;cursor: pointer !important;"
                   @click="navigateToDeviceReport(scope.row['device'], scope.row)">
                <span style="width: 100% ;height: 100% ;cursor: pointer !important;">{{ scope.row['device'] }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="基础信息" class="custom-div ">
            <template #default="scope">
              <div style="cursor: default !important;  box-shadow: none !important;">
                <DeviceSummary :info="scope.row"/>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="网络和报告" class="custom-div">
            <template #default="scope">
              <div style="cursor: default !important;  box-shadow: none !important;">
                <DeviceTag :info="{org:scope.row['机构标签'],reports:scope.row['reports']}"/>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250" v-if="!isOrg" style="cursor: default">
            <template #default="scope">
              <el-button type="primary" size="small" style="width: 90px;" @click="openEditDialog(scope.row)">修改信息
              </el-button>
              <el-button type="primary" size="small" style="width: 90px;"
                         @click="navigateToDeviceReport(scope.row['device'], scope.row)">查看报告
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination-container">
          <el-pagination
              v-model:currentPage="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 50, 100]"
              layout="total, sizes, prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <DeviceEditDialog v-model="editDialogVisible" :editDialogVisible="editDialogVisible" :data="deviceInfo"
                        :special="special_org" @update:editDialogVisible="handleEditDialogClose"/>
      <el-dialog title="生成报告" v-model="reportDialogVisible" width="50%" :modal-append-to-body="false">
        <el-date-picker
            v-model="dateRange"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
        />
        （️时间跨度不能超过24小时）
        <template #footer>
        <span class="dialog-footer">
          <el-button @click="reportDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="generateReport">生成报告</el-button>
          <el-button type="primary" @click="generate24HourReport">生成24小时报告</el-button>
        </span>
        </template>
      </el-dialog>
      <div v-if="current_detail !== null && current_detail !== '' && device_reports" class="report">
        <ReportDetailv2 v-if="current_detail" v-model:device="current_detail" :selections="row_data" :stage="1"
                        :special="special_org" :isShenzhenHospital:="user.isShenzhenHospital"/>
      </div>
    </div>
  </div>
</template>
<script setup>
import {computed, onMounted, ref, watch, watchEffect} from 'vue';
import DeviceSummary from '@/components/DeviceSummary.vue';
import ReportDetail from '@/components/ReportDetail.vue';
import ReportDetailv2 from "@/components/ReportDetailv2.vue";
import CustomFilter from '@/common/CustomFilter.vue';
import DeviceTag from '@/components/DeviceTag.vue';
import {do_task, wdb_get, wdb_get_history_new, createAxiosInstance} from '@/plugins/axios';
import DeviceEditDialog from '@/components/DeviceEditDialog.vue';
import {useStore} from 'vuex';
import {useJumpData} from '@/plugins/checkJumpData';
import {ElMessage} from 'element-plus';

const current_detail = ref('');
const rows = ref([]);
const total = ref(100);
let fetching = 0;
let encodedFilterStr = '';
const currentPage = ref(1);

const row_data = ref({});
const editDialogVisible = ref(false);
const reportDialogVisible = ref(false);
const deviceInfo = ref({});
const user = computed(() => store.state.user)
const {checkJumpData} = useJumpData();
const store = useStore()
const report_device = ref('')
const special_org = computed(() => user.value && (user.value.special || user.value.org === '广安门医院检验科' || user.value.org === '雪橇国家集训队'));
const selectedCard = ref(null) // Store the index of the selected card
const device_reports = ref(1)
const pageSize = ref(10);
// Define functions
const handleCardClick = (index, row) => {
  selectedCard.value = index
  navigateToDeviceReport(row.device, row)
}

const isOrg = computed(() => user.value && user.value.isOrg)
const currentRow = ref(null); // 存储当前选中的行

const fetchData = async () => {
  if (fetching) return;
  let pageSize = 10;
  if (special_org.value) {
    pageSize = 100
  }
  try {
    fetching = 1;
    const axiosInstance = createAxiosInstance('收集');

    const response = await axiosInstance.get('/device/?filter=' + encodedFilterStr + '&page=' + currentPage.value + '&pagesize=' + pageSize);
    fetching = 0;

    const nodesData = response.data.data.nodes;
    for (const node of nodesData) {
      // 获取每个设备的报告数
      const reportCount = await getReports(node.device);
      // 添加 reports 字段
      node.reports = reportCount;
    }
    rows.value = nodesData;
    total.value = response.data.data.count;
  } catch (error) {
    console.error('获取数据失败:', error);
    fetching = 0;
  }
};

const reports = ref(0)

async function getReports(device) {
  reports.value = 0;
  const values = await wdb_get_history_new('收集', '报告统计天', device, 60);
  return values.filter(item => item !== null).length;
}

const handleSizeChange = (newSize) => {
  pageSize.value = newSize;
  fetchData();
};
const navigateToDeviceReport = async (deviceid, row) => {
  const values = await wdb_get_history_new('收集', '报告统计天', deviceid, 60);
  // 检查 values 数组是否不为 null, undefined, 或空，并且所有元素都不为 null
  if (values !== null && values !== undefined && values.length > 0 && values.some(value => value !== null)) {
    current_detail.value = deviceid;
    row_data.value = row;
  } else {
    current_detail.value = '';
  }
};

const handleCurrentChange = (val) => {
  currentPage.value = val;
  fetchData();
};

const handleFilterChange = async (filterStr) => {
  encodedFilterStr = encodeURIComponent(filterStr);
  fetchData();
};
const openEditDialog = async (rowData) => {
  const axiosInstance = createAxiosInstance('收集');

  const response = await axiosInstance(`/deviceinfo/${rowData.device}/`);
  deviceInfo.value = {...response.data.data, device: rowData.device};
  editDialogVisible.value = true;
};

const openReportDialog = async (rowData) => {
  console.log(rowData)
  reportDialogVisible.value = true;
  report_device.value = rowData.device
};
const dateRange = ref([]);
const pickerOptions = {
  disabledDate(time) {
    // Disable dates after today
    return time.getTime() > Date.now();
  }
};

watch(dateRange, (newVal) => {
  if (newVal.length === 2) {
    const [start, end] = newVal;
    const duration = end - start;
    if (duration > 24 * 60 * 60 * 1000) {
      ElMessage.error('只能选择 24 小时以内的时间范围');
      dateRange.value = [];
    }
  }
});
const task = {
  task: '流式计算',
  start: 0,
  end: 0,
  device: '',
  type: 'report'
}
const generateReport = async () => {
  if (dateRange.value.length !== 2) {
    ElMessage.error('请选择时间范围');
    return;
  }
  const [start, end] = dateRange.value.map(date => Math.floor(date.getTime() / 1000));
  task.start = start
  task.end = end
  task.device = report_device.value
  const response = await do_task('dotask', task);
  console.log(response)
  reportDialogVisible.value = false;
  task.start = 0
  task.end = 0
  task.device = ''
};

const generate24HourReport = async () => {
  const end = Math.floor(Date.now() / 1000); // Convert to seconds
  const start = end - 24 * 60 * 60;
  task.start = start
  task.end = end
  task.device = report_device.value

  const response = await do_task('dotask', task);
  console.log(response);

  ElMessage.success('24小时报告生成中');
  reportDialogVisible.value = false;
};

function handleEditDialogClose(visible) {
  console.log(visible)
  fetchData();

  editDialogVisible.value = false;
}

// 添加行点击处理函数
const handleRowClick = (row) => {
  currentRow.value = row;
};

function getCardClass(row) {
  const online = row['设备和网络']['在线'];
  const reportGenerated = row.sleep_status.sleepreport_state === 1;

  if (!online) {
    return 'offline';
  }

  return reportGenerated ? 'online-with-report' : 'online-without-report';
}

function getOsaLevel(osa) {
  if (osa >= 30) {
    return {level: '重度', color: '#E92D2E'};
  } else if (osa >= 15) {
    return {level: '中度', color: '#FB8700'};
  } else if (osa >= 5) {
    return {level: '轻度', color: '#FFBA2F'};
  } else if (osa >= 0) {
    return {level: '正常', color: '#00BB35'};
  }
  return {level: '', color: '#ffffff'}; // 默认值
}

const getSpanStyle = (color) => {
  return {
    color: '#FFFFFF',
    backgroundColor: color,
    padding: '2px 4px',
    borderRadius: '4px',
    marginLeft: '8px'
  };
};

onMounted(async () => {
  checkJumpData()
  fetchData();
});
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  width: 100%;
  cursor: default !important;
}

.data-container {
  width: 60%;
  margin-right: 20px;
  height: 75vh;
  display: flex;
  flex-direction: column;
  cursor: default !important;
}

.ort-data-container {
  width: 60%;
  margin-top: 3%;
  margin-right: 20px;
  height: 75vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.ort-data-container .card-column {
  flex: 0 0 calc(50% - 10px);
  margin-bottom: 5%;
}

.custom-card {
  width: calc(100% - 30px);
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-card:hover {
  cursor: pointer;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stat-item h2,
.stat-item p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination-container {
  flex-shrink: 0;
}

.report {
  width: 40%;
  margin-top: 17px;
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.selected-card {
  border: 2px solid #00bbb5;
  box-shadow: 0 0 10px rgba(0, 187, 181, 0.5);
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align buttons */
  gap: 10px; /* Adjust the spacing between buttons as needed */
}

.el-button {
  width: 100%; /* Ensure buttons take full width of container */
}

.device-table .el-table__body-wrapper td * {
  text-align: center;
  padding: 10px;
  cursor: default;
}

.device-table .custom-div:hover {
  box-shadow: none !important;
}

.el-table__row * {
  cursor: default !important;
}

.current-row * {
  cursor: default !important;
}

.online-with-report {
  background-color: white;
}

.online-without-report {
  background-color: #d3d3d3;
}

.offline {
  background-color: #ef8b8b;
}

.deep .el-table td.el-table__cell div {
  cursor: default !important;
  box-shadow: none !important;
}

.el-dialog {
  top: 10vh; /* Adjust the dialog position */
  height: 60vh; /* Increase the height of the dialog */
  max-height: 60vh; /* Ensure the dialog does not exceed this height */
}

.dialog-footer {
  display: flex;
  justify-content: space-between; /* Align buttons in a row */
}

</style>
