<template>
    <canvas ref="tagCanvas" :style="{width:'100%',height:'100%',cursor:'pointer' }"></canvas>
</template>

<script setup>
import { onMounted, ref, defineProps, onUnmounted, watch, toRaw } from 'vue';
import { add_event_list, get_event_pos } from '@/plugins/timebuffer';
// Props
const props = defineProps({
  listobj: Object,
  color: String,
  selstart: Number,
  selend: Number,
  last_modified: Number,
  last_realtime: Number,
  last_resized: Number
});

const tagCanvas = ref(null);
let resizeObserver;
let recent_data = null;
function beforeDrawEvent (ctx, data) {
  if (data.data && data.data.length > 0) {
    data = data.data[0]
  }
  const info = data.event_info

  const event_type = data.event_type
  let strokeStyle = 'red'
  let lineWidth = 1
  ctx.lineWidth = 1
  if (!event_type) {
    console.log('draw event ' + data + ' err')
    return
  }

  if (info && info.line) {
    lineWidth = info.line
  }
  if (info && info.stroke) {
    strokeStyle = info.stroke
  } else
    if (event_type.includes('invalid')) {
      strokeStyle = 'green'
    } else
      if (event_type === 'fragment_action') {
        strokeStyle = 'white'
      } else
        if (event_type === 'fragment_snore') {
          strokeStyle = 'yellow';
        } else
          if (event_type.includes('Hypopnea') || event_type.includes('Apnea')) {
            if (data.event_value > 1) { strokeStyle = 'yellow'; } else { strokeStyle = 'green'; }
          } else
            if (event_type === 'long_artifact') {
              strokeStyle = 'lightblue';
            } else
              if (event_type === 'sendreupload') {
                strokeStyle = 'white';
              } else
                if (event_type === 'connect') {
                  strokeStyle = 'yellow';
                  lineWidth = 3
                }
  ctx.strokeStyle = strokeStyle
  ctx.lineWidth = lineWidth
}

const drawChannel = () => {
  const canvas = tagCanvas.value;
  if (props.listobj == null || canvas == null || props.listobj.data == null) return;
  recent_data = props.listobj;
  if (canvas.width !== canvas.clientWidth || canvas.height !== canvas.clientHeight) {
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;
  }
  const paint_info = get_event_pos(recent_data.data, props.selstart, props.selend, canvas.width);
  // console.log("draw data "+obj.name+" "+formatDateTime(nowtime))
  const ctx = canvas.getContext('2d')
  recent_data.paint_data = paint_info
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  for (const type in paint_info) {
    if (type !== 'source') {
      for (const i in paint_info[type]) {
        const data = paint_info[type][i]
        beforeDrawEvent(ctx, data)
        const x1 = data.x1
        const x2 = data.x2
        ctx.fillStyle = props.color
        ctx.strokeRect(data.x1, 25, x2 - x1, canvas.height - 50);
        ctx.fillStyle = 'white'
      }
    }
  }
};
watch(
  [() => props.last_modified],
  () => {
    recent_data = props.listobj;
    for (const i in recent_data.data.realtime_history) {
      const obj = recent_data.data.realtime_history[i];
      add_event_list(recent_data.data, obj);
    }
    drawChannel()
  }
);
watch(
  () => props.last_realtime,
  () => {
    recent_data = props.listobj;
    while (recent_data.data.realtime.length > 0) {
      const obj = recent_data.data.realtime.shift();
      let data = toRaw(obj);
      if (Array.isArray(data)) {
        data = data[0];
      }
      const obj2 = { 'data':[data] }
      add_event_list(recent_data.data, obj2);
      recent_data.data.realtime_history.push(obj2);
    }
    drawChannel();
  }
);
watch(
  [() => props.last_resized],
  () => drawChannel()
);

onMounted(() => {
  const canvas = tagCanvas.value;
  resizeObserver = new ResizeObserver(() => {
    drawChannel(); // 尺寸变化时触发重绘
  });
  resizeObserver.observe(canvas);
  canvas.addEventListener('mousemove', () => {
    handleMouseMove();
  });
  canvas.addEventListener('mouseleave', () => {
    // tooltipVisible.value = false;
  });
  canvas.addEventListener('click', () => {
    handleClick();
  });
  drawChannel();
});

onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect(); // 清理监听器
  }
});
function handleMouseMove () {
}

function handleClick () {

}

</script>

<style scoped>
    canvas {
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
