<template>
  <div class="batch-container">
    <!-- 顶部控制区 -->
    <el-collapse v-model="activeCollapse">
      <el-collapse-item name="filter">
        <template #title>
          <div class="collapse-header">
            <span>筛选条件</span>
            <el-tag size="small" type="info" class="filter-count" v-if="hasActiveFilters">
              已设置 {{ activeFiltersCount }} 个筛选条件
            </el-tag>
          </div>
        </template>
        <div class="control-panel">
          <div class="filter-section">
            <CustomFilter item="device" @filter-changed="handleFilterChange"/>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

    <!-- 主要内容区 -->
    <draggable 
      v-model="moduleOrder"
      :component-data="{
        tag: 'div',
        name: 'fade-group'
      }"
      class="main-content"
      handle=".drag-handle"
      item-key="id"
      animation="300"
    >
      <template #item="{element}">
        <div :class="['module-container', element.type + '-module']">
          <div class="module-header">
            <span class="drag-handle">⋮⋮</span>
            <h3>{{ element.name }}</h3>
          </div>
          
          <!-- 设备选择区 -->
          <div v-if="element.type === 'device'" class="device-section">
            <div class="section-title">
              <h3>设备选择</h3>
              <div class="device-count">
                <span>可选设备: {{ total }}</span>
                <span>已选设备: {{ selectList.length }}</span>
              </div>
            </div>
            
            <div class="device-tables">
              <div class="device-list">
                <el-table
                    :data="devices"
                    @selection-change="handleDeviceSelectionChange"
                    :default-selection="defaultSelectedDevices"
                    height="300"
                >
                  <el-table-column type="selection" width="55"></el-table-column>
                  <el-table-column prop="device" label="设备编号" width="180"></el-table-column>
                  <el-table-column prop="status" label="基础信息">
                    <template #default="scope">
                      <DeviceSummary :info="scope.row"/>
                    </template>
                  </el-table-column>
                </el-table>
                
                <!-- 添加按钮区域 -->
                <div class="action-buttons">
                  <el-button 
                    type="primary" 
                    @click="addToSelected" 
                    :disabled="!selectedDeviceIds.length"
                  >
                    添加到已选列表 <el-icon class="el-icon--right"><DArrowRight /></el-icon>
                  </el-button>
                </div>
              </div>

              <div class="selected-devices">
                <div class="selected-header">
                  <div class="header-left">
                    <span>已选设备列表</span>
                    <span class="device-counter">
                      (当前页选中: {{ selectedDeviceIds.length }} / 总计: {{ selectList.length }})
                    </span>
                  </div>
                  <el-button type="danger" link @click="clearSelected">清空</el-button>
                </div>
                <div class="selected-tags-container">
                  <el-tag 
                    v-for="device in selectList" 
                    :key="device" 
                    closable 
                    @close="removeFromSelected(device)"
                    class="device-tag"
                  >
                    {{ device }}
                  </el-tag>
                </div>
              </div>
            </div>

            <el-pagination
                v-model:current-page="currentPage"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
            />
          </div>

          <!-- 任务配置区 -->
          <div v-if="element.type === 'task'" class="task-section">
            <div class="section-title">
              <h3>任务配置</h3>
              <el-tag v-if="taskType" type="info">
                当前任务类型: {{ taskType }}
              </el-tag>
            </div>
            
            <div class="task-config"> 
              <div class="task-lists">
                <el-tabs type="border-card">
                  <el-tab-pane v-for="(tasks, category) in tableData" 
                              :key="category" 
                              :label="category">
                    <div class="task-checkbox-container">
                      <el-checkbox-group 
                        v-model="selectedTasks[category]"
                        @change="(val) => handleTaskSelectionChange(category, val)"
                      >
                        <el-checkbox 
                          v-for="task in tasks"
                          :key="task.id"
                          :label="task"
                        >
                          {{ task.name }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>

              <div class="task-params">
                <div class="param-item">
                  <span class="param-label">运行服务器:</span>
                  <el-select v-model="serverType" style="width: 200px">
                    <el-option 
                      v-for="(endpoint, server) in endpoints" 
                      :key="server"
                      :label="server" 
                      :value="server"
                    />
                  </el-select>
                </div>

                <div class="param-item">
                  <span class="param-label">参数:</span>
                  <template v-if="taskParamsOptions.length > 0">
                    <el-select
                      v-model="taskParams"
                      placeholder="请选择参数"
                      style="width: 200px"
                      filterable
                      allow-create
                    >
                      <el-option
                        v-for="param in taskParamsOptions"
                        :key="param"
                        :label="param"
                        :value="param"
                      />
                    </el-select>
                  </template>
                  <template v-else>
                    <el-input 
                      v-model="taskParams" 
                      placeholder="请输入任务参数"
                      style="width: 200px"
                    />
                  </template>
                </div>

                <div class="param-item">
                  <span class="param-label">时间范围:</span>
                  <el-date-picker
                      v-model="formattedDateRange"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      @change="handleDateChange"
                  />
                </div>

                <el-button type="primary" size="large" @click="runTasks" :disabled="!canRun">
                  运行任务
                </el-button>
              </div>
            </div>
          </div>

          <!-- 运行状态区 -->
          <div v-if="element.type === 'status'" class="status-section">
            <el-collapse v-model="statusActiveCollapse">
              <el-collapse-item name="status">
                <template #title>
                  <div class="collapse-header">
                    <span>运行状态</span>
                    <span class="server-info">当前服务器: {{ serverType }}</span>
                  </div>
                </template>
                
                <div class="status-content">
                  <div class="status-summary">
                    <el-statistic title="运行中任务数" :value="task_list.running_count" />
                    <el-statistic title="等待运行任务数" :value="task_list.notrun_count" />
                    <el-statistic title="运行设备数" :value="task_list.run_count" />
                  </div>

                  <el-tabs type="border-card">
                    <el-tab-pane label="运行中">
                      <el-table :data="filteredTaskList" class="status-table">
                        <el-table-column prop="device" label="设备" width="180" />
                        <el-table-column label="任务名称">
                          <template #default="scope">
                            <el-tag 
                              v-for="task in scope.row.tasks" 
                              :key="task.name"
                              type="success"
                              class="task-tag"
                            >
                              {{ task.name }}
                            </el-tag>
                          </template>
                        </el-table-column>
                        <el-table-column label="状态" width="100">
                          <template #default="scope">
                            <el-tag type="success">运行中</el-tag>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>

                    <el-tab-pane label="等待运行">
                      <el-table :data="notRunTaskList" class="status-table">
                        <el-table-column prop="device" label="设备" width="180" />
                        <el-table-column label="任务名称">
                          <template #default="scope">
                            <el-tag 
                              v-for="task in scope.row.tasks" 
                              :key="task.name"
                              type="info"
                              class="task-tag"
                            >
                              {{ task.name }}
                            </el-tag>
                          </template>
                        </el-table-column>
                        <el-table-column label="状态" width="100">
                          <template #default="scope">
                            <el-tag type="info">等待运行</el-tag>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script setup>
import {ref, onMounted, watch, computed, onUnmounted, watchEffect} from 'vue';
import {createAxiosInstance, wdb_get} from "@/plugins/axios";
import {DArrowRight, Search, Operation} from "@element-plus/icons-vue";
import DeviceSummary from "@/components/DeviceSummary.vue";
import CustomFilter from "@/common/CustomFilter.vue";
import draggable from 'vuedraggable';
import { ElMessage } from 'element-plus';
import { serverStore } from '@/config/servers'

const currentPage = ref(1);
const pageSize = ref(200);
const inputFilter = ref("");
const devices = ref([]);
const selectedDeviceIds = ref([]);
//选择任务
const taskProgress = ref([]);
let encodedFilterStr = "";
const total = ref(100);
let fetching = 0;
//选择设备
const selectList = ref([]);
//选择日期
const dateRange = ref([])
const tableData = ref({})
const activeNames = ref(['1']); // 默认展开第一个面板
const selectedTasks = ref({});
const task_list = ref([])
let intervalId = null; // 用于存储定时器ID
const endpoints = ref(serverStore.getEndpoints())
const serverType = ref('')

// 添加监听器，在 endpoints 变化时设置默认值
watch(endpoints, (newEndpoints) => {
  if (Object.keys(newEndpoints).length > 0 && !serverType.value) {
    serverType.value = Object.keys(newEndpoints)[0]
  }
}, { immediate: true })

const handleTaskSelectionChange = async (category, selection) => {
  console.log('任务选择变化:', category, selection);
  selectedTasks.value[category] = selection;
  
  // 获取当前选中任务的参数
  taskParams.value = '';
  taskParamsOptions.value = [];
  
  const selectedTaskNames = selection.map(task => task.name).filter(Boolean);
  console.log('选中的任务名称:', selectedTaskNames);
  
  if (selectedTaskNames.length > 0) {
    try {
      const axiosInstance = createAxiosInstance('收集');
      console.log('开始获取任务参数...');
      
      const promises = selectedTaskNames.map(taskName => {
        console.log('请求参数:', `/taskparams/${encodeURIComponent(taskName)}`);
        return axiosInstance.get(`/taskparams/${encodeURIComponent(taskName)}`);
      });
      
      const responses = await Promise.all(promises);
      // 修改这里：从响应中提取 data 数组
      const results = responses.map(res => res.data.data);
      console.log('获取到的参数:', results);
      
      // 合并所有任务的参数选项，去重
      const allParams = new Set(results.flat());
      taskParamsOptions.value = Array.from(allParams);
      console.log('处理后的参数选项:', taskParamsOptions.value);
    } catch (error) {
      console.error('获取任务参数失败:', error);
      ElMessage({
        type: 'error',
        message: '获取任务参数失败',
        duration: 3000
      });
    }
  }
};

const formattedDateRange = ref([]);
const taskParams = ref('');

// 观察 dateRange 的变化
watchEffect(() => {
  if (dateRange.value && dateRange.value.length === 2) {
    const start = new Date(dateRange.value[0]);
    const end = new Date(dateRange.value[1]);

    // 判断是否为同一天
    if (start.toDateString() === end.toDateString()) {
      formattedDateRange.value = [start.toISOString().substring(0, 10)];
    } else {
      formattedDateRange.value = [
        start.toISOString().substring(0, 10),
        end.toISOString().substring(0, 10)
      ];
    }
  }
});

// 处理日期变化
function handleDateChange(value) {
  if (value && value.length === 2) {
    const startDate = new Date(new Date(value[0]).setHours(0, 0, 0, 0)).getTime();
    const endDate = new Date(new Date(value[1]).setHours(23, 59, 59, 999)).getTime();

    // 判断是否为同一天
    if (new Date(startDate).toDateString() === new Date(endDate).toDateString()) {
      dateRange.value = [startDate];
      console.log('选择的日期时间戳:', startDate);
    } else {
      dateRange.value = [startDate, endDate];
      console.log('开始时间戳:', startDate, '结束时间戳:', endDate);
    }
  }
}

async function getData() {
  const task_list = ['report', 'device'];


  for (const type of task_list) {
    selectedTasks.value[type] = [];

    const values = await wdb_get('.system_task', type);
    const newvalues = [];
    let flag = 1;
    for (var i in values) {
      if (i != "refresh_time" && i != "time_diff" && i != "modified")
        newvalues.push({'id': flag, 'name': i});
      flag += 1;
    }
    tableData.value[type] = newvalues

  }
}

function onInputFilterChange() {
  encodedFilterStr = "search:*" + inputFilter.value + "|";
  fetchData();
}

// const handleSizeChange = (newSize) => {
//   pageSize.value = newSize;
//   fetchData();
// };
const handleCurrentChange = (val) => {
  currentPage.value = val;
  fetchData();
};

onMounted(() => {
  getData();
  fetchData();
  fetchTaskData()

  intervalId = setInterval(fetchTaskData, 1000);

});
onUnmounted(() => {

  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});
const fetchData = async () => {
  // if (fetching) return;
  try {
    // fetching = 1;
    console.log(encodedFilterStr)
    console.log(fetching)
    const axiosInstance = createAxiosInstance('收集');
    const response = await axiosInstance.get('/device/?filter=' + encodedFilterStr + '&page=' + currentPage.value + '&pagesize=' + pageSize.value);
    // fetching = 0;
    processResponse(response);
  } catch (error) {
    console.error('失败:', error);
    // fetching = 0;
  }
};
const processResponse = (response) => {
  devices.value = response.data.data.nodes;
  total.value = response.data.data.count;
};
const handleDeviceSelectionChange = (val) => {
  selectedDeviceIds.value = val.map(item => item.device);
}

watch([selectedDeviceIds, selectedTasks], ([newTasks]) => {
  taskProgress.value = newTasks.map(task => ({
    id: task.id,
    name: task.name,
    progress: 0
  }));
})
const addToSelected = () => {
  const set = new Set([...selectList.value, ...selectedDeviceIds.value]);
  selectList.value = Array.from(set);
}
const defaultSelectedDevices = computed(() => {
  const a = devices.value.filter(device => selectList.value.includes(device.device));
  return a
});

// 移除 taskType ref，改为计算属性
const taskType = computed(() => {
  // 检查选中的任务类型
  if (selectedTasks.value.report?.length > 0) {
    return 'report';
  } else if (selectedTasks.value.device?.length > 0) {
    return 'device';
  }
  return ''; // 如果都没有选择，返回空字符串
});

// 修改运行按钮的禁用条件
const canRun = computed(() => {
  return selectList.value.length > 0 && 
         taskType.value !== ''; // 确保有选择任务类型
});

// 修改运行任务函数
async function runTasks() {
  try {
    const axiosInstance = createAxiosInstance(serverType.value);
    await axiosInstance.post('/batch/task/', {
      tasks: selectedTasks.value,
      devices: selectList.value,
      dateRange: dateRange.value,
      taskType: taskType.value,
      params: taskParams.value
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // 请求成功，显示提示
    ElMessage({
      type: 'success',
      message: `成功创建${taskType.value}任务：${selectList.value.length}个设备`,
      duration: 3000
    });

  } catch (error) {
    // 请求失败，显示错误
    ElMessage({
      type: 'error',
      message: '创建任务失败，请重试',
      duration: 3000
    });
  }
}

const removeFromSelected = (device) => {
  selectList.value = selectList.value.filter(item => item !== device);
};
const clearSelected = () => {
  selectList.value = []
}
const handleFilterChange = async (filterStr) => {
  console.log(filterStr)
  encodedFilterStr = encodeURIComponent(filterStr);
  
  fetchData();
};

const fetchTaskData = async () => {
  try {
    fetching = 1;
    const axiosInstance = createAxiosInstance(serverType.value);
    const response = await axiosInstance.get('/tasks/');
    fetching = 0;
    task_list.value = response.data;
  } catch (error) {
    console.error('失败:', error);
    fetching = 0;
  }
};
const formattedTaskList = computed(() => {
  if (Array.isArray(task_list.value.running_list)) {
    return task_list.value.running_list.map(deviceTasks => {
      const device = Object.keys(deviceTasks)[0];
      const tasks = Object.keys(deviceTasks[device]).map(taskName => {
        return {
          name: taskName,
          status: deviceTasks[device][taskName].status
        };
      });
      return {device, tasks};
    });
  }
  return [];
});

// 等待运行任务列表
const notRunTaskList = computed(() => {
  if (Array.isArray(task_list.value.notrun_list)) {
    return task_list.value.notrun_list.map(deviceTasks => {
      const device = Object.keys(deviceTasks)[0];
      // 处理设备下的任务数组
      const tasks = deviceTasks[device].flatMap(task => {
        // 获取任务对象的键（任务名称|日期）
        const taskKey = Object.keys(task)[0];
        // 分割任务名称和日期
        const [taskName] = taskKey.split('|');
        return {
          name: taskName,
          status: task[taskKey].status,
          date: task[taskKey].date,
          log: task[taskKey].log || []
        };
      });
      return {device, tasks};
    });
  }
  return [];
});

// 运行中任务列表
const filteredTaskList = computed(() => {
  if (Array.isArray(task_list.value.running_list)) {
    return task_list.value.running_list.map(deviceTasks => {
      const device = Object.keys(deviceTasks)[0];
      // 处理设备下的任务数组
      const tasks = deviceTasks[device].flatMap(task => {
        // 获取任务对象的键（任务名称|日期）
        const taskKey = Object.keys(task)[0];
        // 分割任务名称和日期
        const [taskName] = taskKey.split('|');
        return {
          name: taskName,
          status: task[taskKey].status,
          date: task[taskKey].date,
          log: task[taskKey].log || []
        };
      });
      return {device, tasks};
    });
  }
  return [];
});

// 修改显示条件
const hasTasksToShow = computed(() => {
  return filteredTaskList.value.length > 0 || notRunTaskList.value.length > 0;
});

// 折叠面板控制
const activeCollapse = ref(['filter']); // 默认展开

// 筛选条件计数（示例实现，根据实际筛选条件调整）
const hasActiveFilters = computed(() => {
  return activeFiltersCount.value > 0;
});

const activeFiltersCount = computed(() => {
  // 这里需要根据实际的筛选条件来计算
  // 示例实现
  return encodedFilterStr ? 1 : 0;
});

// 模块顺序管理
const moduleOrder = ref([
  { id: 1, type: 'device', name: '设备选择' },
  { id: 2, type: 'task', name: '任务配置' },
  { id: 3, type: 'status', name: '运行状态' }
]);

// 保存模块顺序到本地存储
watch(moduleOrder, (newOrder) => {
  localStorage.setItem('batchViewModuleOrder', JSON.stringify(newOrder));
}, { deep: true });

// 初始化时从本地存储加载模块顺序
onMounted(() => {
  const savedOrder = localStorage.getItem('batchViewModuleOrder');
  if (savedOrder) {
    try {
      moduleOrder.value = JSON.parse(savedOrder);
    } catch (e) {
      console.error('Error loading saved module order:', e);
    }
  }
});

// 状态区域的折叠控
const statusActiveCollapse = ref(['status']); // 默认展开

// 添加新的响应式变量
const taskParamsOptions = ref([]);
</script>

<style scoped>
.batch-container {
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
}

.control-panel {
  margin-bottom: 20px;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.section-title h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.device-section,
.task-section,
.status-section {
  background: white;
  border-radius: 8px;
  padding: 20px;
}

.device-count {
  display: flex;
  gap: 20px;
  color: #666;
}

.device-tables {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
}

.device-list {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  padding: 12px 0;
  background: #f8f9fa;
  border-radius: 4px;
}

.selected-devices {
  flex: 1;
  min-width: 300px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: #fff;
  height: 300px; /* 与左侧表格高度一致 */
}

.selected-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ebeef5;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.device-counter {
  color: #909399;
  font-size: 13px;
}

.selected-tags-container {
  flex: 1;
  overflow-y: auto;
  padding: 4px;
}

.device-tag {
  margin: 4px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-config {
  display: flex;
  gap: 40px;
  height: 400px; /* 固定高度 */
}

.task-lists {
  flex: 2;
  display: flex;
  flex-direction: column;
}

:deep(.el-tabs) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:deep(.el-tabs__content) {
  flex: 1;
  overflow: hidden; /* 防止内容溢出 */
}

:deep(.el-tab-pane) {
  height: 100%;
}

.task-checkbox-container {
  height: 100%;
  overflow-y: auto;
  padding: 10px;
}

/* 自定义滚动条样式 */
.task-checkbox-container::-webkit-scrollbar {
  width: 6px;
}

.task-checkbox-container::-webkit-scrollbar-thumb {
  background-color: #dcdfe6;
  border-radius: 3px;
}

.task-checkbox-container::-webkit-scrollbar-track {
  background-color: #f5f7fa;
}

:deep(.el-checkbox-group) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.task-params {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 4px;
  height: 100%; /* 确保参数区域也是全高的 */
  overflow-y: auto;
}

.param-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.param-label {
  min-width: 80px;
  color: #606266;
}

.status-summary {
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
}

.task-tag {
  margin: 2px 4px;
}

.server-info {
  font-size: 14px;
  color: #909399;
}

:deep(.el-tabs--border-card) {
  box-shadow: none;
}

:deep(.el-checkbox-group) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

:deep(.el-table) {
  --el-table-border-color: #ebeef5;
}

/* 自定义滚动条样式 */
.selected-tags-container::-webkit-scrollbar {
  width: 6px;
}

.selected-tags-container::-webkit-scrollbar-thumb {
  background-color: #dcdfe6;
  border-radius: 3px;
}

.selected-tags-container::-webkit-scrollbar-track {
  background-color: #f5f7fa;
}

/* 响应式调整 */
@media (max-width: 1200px) {
  .device-tables {
    flex-direction: column;
  }
  
  .selected-devices {
    min-width: 100%;
    height: 200px; /* 在移动端减小高度 */
  }
}

.status-section {
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.status-content {
  padding: 16px;
}

.collapse-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.server-info {
  font-size: 13px;
  color: #909399;
  margin-right: 24px;
}

.status-summary {
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 4px;
}

:deep(.el-collapse-item__content) {
  padding: 0;
}

:deep(.el-tabs--border-card) {
  border: 1px solid #dcdfe6;
  box-shadow: none;
}

.task-tag {
  margin: 2px 4px;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .status-summary {
    flex-direction: column;
    gap: 20px;
  }
  
  .collapse-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .server-info {
    margin-right: 0;
  }
}

.collapse-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.filter-count {
  font-size: 12px;
}

.control-panel {
  padding: 16px;
  background: #fff;
  border-radius: 4px;
}

.task-type-select {
  margin-bottom: 16px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 12px;
}

:deep(.el-collapse-item__header) {
  font-size: 16px;
  font-weight: 500;
  background: #f8f9fa;
}

:deep(.el-collapse) {
  margin-bottom: 20px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.main-content {
  margin-top: 20px;
}

.param-label {
  color: #606266;
  font-size: 14px;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .task-type-select {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .task-type-select .el-select {
    width: 100% !important;
  }
}

.section-title .el-tag {
  margin-left: 12px;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.module-container {
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.module-header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  background: #f8f9fa;
  border-bottom: 1px solid #ebeef5;
}

.module-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.drag-handle {
  cursor: move;
  color: #909399;
  font-size: 20px;
  padding: 0 8px;
  user-select: none;
}

.drag-handle:hover {
  color: #409EFF;
}

/* 拖拽时的样式 */
.sortable-ghost {
  opacity: 0.5;
  background: #f0f9ff;
}

.sortable-drag {
  opacity: 0.9;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .module-header {
    padding: 8px 12px;
  }
}

.collapse-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.filter-count {
  font-size: 12px;
}

:deep(.el-collapse) {
  --el-collapse-border-color: #e4e7ed;
  border: 1px solid var(--el-collapse-border-color);
  border-radius: 4px;
  overflow: hidden;
}

:deep(.el-collapse-item__header) {
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  background-color: var(--el-bg-color);
  border-bottom: 1px solid var(--el-collapse-border-color);
  transition: all 0.3s ease;
}

:deep(.el-collapse-item__header:hover) {
  background-color: var(--el-fill-color-light);
}

:deep(.el-collapse-item__arrow) {
  margin-right: 0;
  transition: transform 0.3s ease;
}

:deep(.el-collapse-item__wrap) {
  background-color: white;
}

:deep(.el-collapse-item__content) {
  padding: 16px;
}

.control-panel {
  background: white;
}

.filter-section {
  padding: 4px 0;
}

/* 响应式调整 */
@media (max-width: 768px) {
  :deep(.el-collapse-item__header) {
    padding: 0 12px;
    height: 40px;
    line-height: 40px;
  }

  :deep(.el-collapse-item__content) {
    padding: 12px;
  }
}

/* 添加参数选择相关样式 */
:deep(.el-select-dropdown__item) {
  padding: 0 12px;
}

:deep(.el-select .el-input__inner) {
  font-size: 14px;
}
</style>