<template>
  <div class="login-overlay" v-show="isVisible">
    <div class="login-box">
      <!-- 直接使用 @submit.prevent 监听表单提交 -->
      <el-form ref="loginForm" @submit.prevent="handleLogin">
        <h3 class="login-title">登录</h3>
        <el-form-item label="账号">
          <el-input 
            v-model="loginForm.username" 
            autocomplete="off"
            :disabled="isLoading"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input 
            type="password" 
            v-model="loginForm.password" 
            autocomplete="off"
            :disabled="isLoading"
          ></el-input>
        </el-form-item>
        <el-form-item class="login-action">
          <el-button 
            type="primary" 
            native-type="submit"
            :loading="isLoading"
            :disabled="isLoading"
          >
            {{ isLoading ? '登录中...' : '登录' }}
          </el-button>
        </el-form-item>
        <div v-if="loginError" class="login-error">{{ loginError }}</div>
        <div v-if="loginSuccessMessage" class="login-success">{{ loginSuccessMessage }}</div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { clearAxiosInstances, createAxiosInstance } from '@/plugins/axios';
import { serverApi } from '@/api/serverApi';
import { serverStore } from '@/config/servers';
import { closeAllWebSockets, connectWebSockets, eventBus, sendCmd } from '@/plugins/websocket';
import md5 from 'md5';

export default {
  props: {
    isVisible: Boolean
  },
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginError: false,
      loginSuccessMessage: '',
      isLoading: false
    };
  },
  methods: {
    async handleLogin () {
      event.preventDefault();
      this.loginError = false;
      this.loginSuccessMessage = '';
      this.isLoading = true; // 开始加载

      try {
        const axios = createAxiosInstance('收集');

        const loginData = {
          username: this.loginForm.username,
          password: md5(this.loginForm.password)
        };

        const response = await axios.post('/login/', loginData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        if (response.status === 200) {
          const token = response.data.data.token;
          localStorage.setItem('authToken', token);
          
          this.loginSuccessMessage = '登录成功！正在初始化...';

          try {
            // 1. 清除旧的连接和实例
            clearAxiosInstances();
            closeAllWebSockets();

            // 2. 获取新的服务器配置
            const servers = await serverApi.getAllServers();
            serverStore.servers = {};
            servers.forEach(server => {
              serverStore.addServer({
                key: server.key,
                type: server.type,
                name: server.name,
                label: server.label,
                apiEndpoint: server.api_endpoint,
                wsEndpoint: server.ws_endpoint,
                order: server.order,
                isDefault: server.isDefault,
                status: server.status,
                refreshTime: server.refresh_time,
                timeDiff: server.time_diff,
                modified: server.modified
              });
            });

            // 3. 重新建立 WebSocket 连接
            await this.reconnectWebSockets();

            // 4. 更新用户信息
            await this.$store.dispatch('fetchUser');

            // 5. 跳转到首页
            await this.$router.push('/');

            // 6. 触发数据刷新
            this.refreshPageData();

          } catch (error) {
            console.error('初始化失败:', error);
            serverStore.loadFromStorage();
            await this.reconnectWebSockets();
            await this.$router.push('/');
            this.refreshPageData();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.loginError = '请输入正确的用户或密码，在非微信浏览器中使用';
        } else {
          this.loginError = '登录失败：' + error.message;
        }
        console.log('用户或密码错误');
      } finally {
        this.isLoading = false; // 结束加载
      }
    },

    async reconnectWebSockets() {
      return new Promise((resolve) => {
        // 重新连接 WebSocket
        connectWebSockets();
        
        // 等待一小段时间确保 WebSocket 连接建立
        setTimeout(() => {
          resolve();
        }, 1000); // 等待1秒确保连接建立
      });
    },

    refreshPageData() {
      // 发送所有需要的数据请求
      sendCmd('statistics_device').then(() => {
        console.log('statistics_device refreshed');
      });
      
      sendCmd('sysinfo').then(() => {
        console.log('sysinfo refreshed');
      });

      // 为每个服务器发送 currentuser 命令
      serverStore.getAllServers().forEach(server => {
        if (server.type !== 'collect') {
          sendCmd('currentuser', {}, server.name).then(() => {
            console.log(`currentuser from ${server.name} refreshed`);
          });
        }
      });

      // 触发一个全局事件，通知需要刷新的组件
      eventBus.emit('force-refresh');
    }
  }
}
</script>
<style scoped>
.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 设置大一些的z-index值 */
  /* 其他样式保持不变 */
}

.login-box {
  padding: 20px;
  background: white;
  border: 1px solid #ccc; /* 边框 */
  border-radius: 8px; /* 圆角 */
  width: 300px; /* 根据需要调整 */
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
}

.login-action {
  display: flex;
  justify-content: center;
}

.login-error {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.login-success {
  color: #67C23A;
  text-align: center;
  margin-top: 10px;
}

/* 禁用状态下的输入框样式 */
:deep(.el-input.is-disabled .el-input__inner) {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #606266;
  cursor: not-allowed;
}
</style>
