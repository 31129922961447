<template>
  <div class="device-report-view">
    <!-- 顶部栏 -->
    <DeviceDateSelect
        v-model:selectedDevice="selectedDevice"
        v-model:selectedReportDate="selectedReportDate"
        :selections="props.selections"
        :sys=1
    />
    <!-- 设备详细数据 -->
    <el-tabs v-model="selectedTask">
      <template v-for="server in serverList" :key="server.key">
        <!-- 设备标签 -->
        <el-tab-pane 
          :name="`设备-${server.name}`" 
          :label="server.name"
        ></el-tab-pane>
        <!-- 任务标签 -->
        <el-tab-pane 
          :name="`任务-${server.name}`" 
          :label="`任务(${server.name})`"
        ></el-tab-pane>
      </template>
    </el-tabs>

    <!-- 使用动态判断来显示内容 -->
    <template v-for="server in serverList" :key="server.key">
      <!-- 设备视图 -->
      <div class="container" v-if="selectedTask === `设备-${server.name}`">
        <div class="snapshot-wrapper">
          <el-card class="header-card" v-if="activetask && activetask.length>0">
            <div>进行中任务</div>
            <TreeViewObj :data="activetask"/>
          </el-card>
          <device-snapshot 
            :device="selectedDevice" 
            :date="selectedReportDate" 
            v-model:globalstart="globalStart"
            v-model:globalend="globalEnd" 
            v-model:selected="selectedChannels" 
            :server="server.name" 
            :mode="system"
          />
        </div>
        <div class="waveform-wrapper">
          <device-waveform 
            :device="selectedDevice" 
            mode="system" 
            :date="selectedReportDate" 
            :globalstart="globalStart"
            :globalend="globalEnd" 
            :visible="selectedChannels" 
            :server="server.name"
          />
        </div>
      </div>

      <!-- 任务视图 -->
      <div class="container" v-if="selectedTask === `任务-${server.name}`">
        <div class="tasks-view">
          <task-view
            :device="selectedDevice"
            :date="selectedReportDate"
            :serverType="server.name"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, onMounted, onUnmounted, defineEmits, computed } from 'vue';

import DeviceDateSelect from '@/common/DeviceDateSelect.vue';
import DeviceSnapshot from './DeviceSnapshot.vue';
import TaskView from './TaskView.vue';
import DeviceWaveform from './DeviceWaveform.vue';
import { request_data, wdb_get_new } from '@/plugins/axios.js';
import { getDateTimestamp, isToday } from '@/plugins/timetool.js';
import { ElMessageBox } from 'element-plus';
import TreeViewObj from '@/common/TreeViewObj.vue';
import { serverStore } from '@/config/servers'

// 接收prop
const props = defineProps({
  device: String,
  selections: Array,
  server: {
    type: String,
    default: '收集'
  }
});
const selectedChannels = ref([]);

const emit = defineEmits(['update:device'])

// 定义 emit 函数，用于触发事件
const selectedDevice = ref('-system')
const selectedReportDate = ref(''); // 选中的报告日期
const selectedTask = ref('')
const globalStart = ref(0)
const globalEnd = ref(0)
const current_device_detail = ref({})
const server = ref('收集')
let intervalId = null;

// 获取排序后的服务器列表
const serverList = computed(() => {
  return serverStore.getAllServers().sort((a, b) => a.order - b.order)
})

watch([() => selectedReportDate.value, () => selectedDevice.value], async ([newDate, newDevice], [oldDate, oldDevice]) => {
  if (newDevice === '' || newDevice !== oldDevice) {
    emit('update:device', newDevice); // 确保这段代码在正确的上下文中
  } else if (oldDate !== newDate) {
    globalStart.value = new Date(newDate + ' 00:00:00').getTime() / 1000;
    globalEnd.value = globalStart.value + 3600;
  }
}, { immediate: true });

let task_current = null
let systemtask_current = null
const activetask = ref([])
let requesting_task = 0

// 计算当前选中的服务器名称
watch(serverList, (newList) => {
  if (newList.length > 0 && !selectedTask.value) {
    // 默认选择第一个服务器的设备视图
    selectedTask.value = `设备-${newList[0].name}`
  }
}, { immediate: true })

async function getTasks () {
  if (!props.device || requesting_task) return
  requesting_task = 1
  const time = selectedReportDate.value ? getDateTimestamp(selectedReportDate.value) : null
  const detail = []
  current_device_detail.value = await request_data(`/devicedetail/${props.device}/`, server.value)
  task_current = await wdb_get_new(server.value, 'task', props.device, null, time)
  systemtask_current = await wdb_get_new(server.value, 'systemtask', props.device, null, time)
  for (const task in task_current) {
    if (task_current[task].status === 'running') {
      detail.push(task_current[task])
    }
  }
  for (const task in systemtask_current) {
    if (systemtask_current[task].status === 'running') {
      detail.push(systemtask_current[task])
    }
  }
  activetask.value = detail
  requesting_task = 0
}

onMounted(async () => {
  intervalId = setInterval(getTasks, 1000);
});
onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});

</script>

<style scoped>
.header-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.container {
  display: flex;
  flex-direction: row; /* 水平布局 */
  width: 100%; /* 确保容器占满整个父元素宽度 */
}

.snapshot-wrapper {
  flex: 0 0 20%; /* flex-grow: 0, flex-shrink: 0, flex-basis: 20% */
  margin: 4px;
  height: 600px;
  /* 这里表示snapshot-wrapper不会随着容器大小的变化而增长或缩小，其基础大小为容器宽度的20% */
}

.waveform-wrapper {
  flex-grow: 1; /* 使用flex-grow: 1，意味着这个元素会占据剩余的空间 */
  /* 这里不需要设置flex-basis，因为默认值是0%，flex-grow会让它自动填满剩余空间 */
  margin: 4px;
}

.tasks-view {
  flex-grow: 1; /* 使用flex-grow: 1，意味着这个元素会占据剩余的空间 */
  /* 这里不需要设置flex-basis，因为默认值是0%，flex-grow会让它自动填满剩余空间 */
  margin: 4px;
}

.userReport {
  flex-direction: row; /* 水平布局 */
}

.spacer {
  height: 50px; /* 添加一个空白填充的高度，可以根据需要调整 */
}

</style>
