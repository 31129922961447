<template>
    <div class="device-snapshot">
        <div class="toolbar">
            <div class="slider-container">
                范围:
                <el-slider v-model="timelevel"
                           :min="0"
                           :max="2"
                           :step="1"
                           :marks="marks"
                           :show-tooltip="false"
                ></el-slider>

  <el-select v-model="selectedSubscription" placeholder="选择订阅" style="margin-left: 10px; width: 200px;" @change="handleSubscriptionChange">
  <!-- 预定义的选项 -->
    <el-option v-for="(sub, index) in subscriptions" :key="index" :label="sub.name" :value="sub.name"></el-option>
  <!-- 分隔线，使用自定义样式 -->
  <el-divider class="small-divider"></el-divider>
  <el-option label="全选" value="all"></el-option>
  <el-option label="全不选" value="none"></el-option>
  <!-- 分隔线，使用自定义样式 -->
  <el-divider class="small-divider"></el-divider>
  <!-- 用户自定义的订阅列表 -->
  <!-- 功能项 -->
  <el-option v-if="selectedSubscription !== 'all' && selectedSubscription !== 'none'" label="保存" value="save"></el-option>
  <el-option label="另存为" value="saveAs"></el-option>
  <el-option v-if="selectedSubscription !== 'all' && selectedSubscription !== 'none'" label="删除" value="delete"></el-option>
</el-select>

            </div>
        </div>

        <!-- 复选框组 -->
        <div class="checkbox-group">
            <div v-for="(channel, index) in channels" :key="channel" class="checkbox-item">
                <input type="checkbox" v-model="selectedChannels" :value="channel" :id="`channel-${index}`">
                <label :for="`channel-${index}`" :style="{ color: channelColors[index % channelColors.length] }">{{ channel }}</label>
            </div>
        </div>

        <!-- 热图组件 -->
        <div class="heatmap">
            <ChannelCanvas v-for="channel in selectedChannels"
                           :key="channel"
                           :channel-data="channel_datas[channel]"
                           :color="channelColors[channels.indexOf(channel) % channelColors.length]"
                           :seltimelen="timelevel"
                           @click-position="handleClickPosition"/>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits, onMounted } from 'vue';
import ChannelCanvas from './ChannelCanvas.vue';
import { request_data } from '@/plugins/axios.js';
import { loadLocalStorage, saveLocalStorage } from '@/plugins/store';

const valueMap = [60, 720, 1440];
const marks = {
  0: '60分(高清)',
  1: '12时',
  2: '24时'
};
const timelevel = ref(1);

const props = defineProps({
  device: String,
  date: String,
  globalstart: Number,
  globalend: Number,
  hf: Number,
  mode: {
    type: String,
    default: 'device'
  },
  selected: {
    type: Array,
    default: () => []
  },
  server: {
    type: String,
    default: '收集'
  }
});

const emit = defineEmits(['update:globalstart', 'update:globalend', 'update:selected', 'update:hf']);

const channels = ref([]);
const channelColors = ['lime', 'yellow', 'red', 'pink', 'aqua', 'white', 'orange', 'brown'];
const channel_datas = ref([]);
const selectedChannels = ref([]);
const subscriptions = ref([]);
const selectedSubscription = ref('all');

// 定义 localStorage 存储键名
// 使用 mode 前缀区分不同页面的数据存储
const storageKey = `${props.mode}-subscriptionData`;

// 初始化时加载订阅列表和上次选择的订阅名
onMounted(() => {
  loadSubscriptions();
  handleSubscriptionChange(selectedSubscription.value);
});

// 加载订阅列表和上次选择的订阅名
// 加载订阅列表和上次选择的订阅名
function loadSubscriptions() {
  const savedData = loadLocalStorage(storageKey) || {};
  // 初始化 subscriptions 和 lastSelectedSubscription
  subscriptions.value = Array.isArray(savedData.subscriptions) ? savedData.subscriptions : [];
  selectedSubscription.value = savedData.lastSelectedSubscription || 'all';
}

// 保存订阅列表和上次选择的订阅名
function saveSubscriptions() {
  const dataToSave = {
    subscriptions: subscriptions.value,
    lastSelectedSubscription: selectedSubscription.value
  };
  saveLocalStorage(storageKey, dataToSave);
}

// 保存当前选择到订阅列表
function saveSubscription (name) {
  const index = subscriptions.value.findIndex(sub => sub.name === name);
  if (index !== -1) {
    subscriptions.value[index].channels = [...selectedChannels.value];
  } else {
    subscriptions.value.push({ name, channels: [...selectedChannels.value] });
  }
  saveSubscriptions();
}
// 非响应式变量，记录当前选中的订阅项名称
let currentSelectedSubscription = 'all';

function handleSubscriptionChange(value) {
  selectedSubscription.value = value;

  // 检查是否为功能选项
  if (value === 'save' || value === 'saveAs' || value === 'delete') {
    handleMenuAction(value);
    return;
  }

  // 更新当前选中的订阅项，跳过功能选项
  if (value !== 'all' && value !== 'none') {
    currentSelectedSubscription = value;
  }

  // 处理 "全选" 和 "全不选"
  if (value === 'all') {
    selectedChannels.value = [...channels.value];
  } else if (value === 'none') {
    selectedChannels.value = [];
  } else {
    const selected = subscriptions.value.find(sub => sub.name === currentSelectedSubscription);
    if (!selected) {
      handleSubscriptionChange('all');
      return;
    }
    selectedChannels.value = [...selected.channels];
  }

  // 保存订阅状态
  saveSubscriptions();
  emit('update:selected', [...selectedChannels.value]);
}

function handleMenuAction(action) {
  if (action === 'save') {
    saveSubscription(currentSelectedSubscription);
    alert('订阅已保存！');
    // 切换到当前保存的订阅项
    handleSubscriptionChange(currentSelectedSubscription);
  } else if (action === 'saveAs') {
    const name = prompt('请输入新的订阅名称：');
    const reservedKeywords = ['save', 'saveAs', 'delete', 'all', 'none'];

    // 检查名称是否为空或为保留关键字
    if (!name || reservedKeywords.includes(name)) {
      alert('名称不能为空，且不能使用保留关键字（如 "删除"、"全选"、"全不选" 等）');
      return;
    }

    if (!subscriptions.value.find(sub => sub.name === name)) {
      saveSubscription(name);
      currentSelectedSubscription = name;
      selectedSubscription.value = name;
      alert('订阅已另存为新名称！');
      // 切换到新保存的订阅项
      handleSubscriptionChange(name);
    } else {
      alert('该订阅名称已存在，请使用其他名称。');
    }
  } else if (action === 'delete') {
    // 使用非响应式变量确认删除的订阅名称
    const confirmDelete = confirm(`确认要删除订阅 "${currentSelectedSubscription}" 吗？`);
    if (confirmDelete) {
      const index = subscriptions.value.findIndex(sub => sub.name === currentSelectedSubscription);
      if (index !== -1) {
        subscriptions.value.splice(index, 1);
        currentSelectedSubscription = 'all';
        selectedSubscription.value = 'all';
        saveSubscriptions();
        alert('订阅已删除！');
        // 显式切换到 "全选"
        handleSubscriptionChange('all');
      }
    }
  }
}

// 处理点击位置
function handleClickPosition (position) {
  position.date = props.date;
  const globalstart = new Date(props.date + ' 00:00:00').getTime() / 1000 + (position.rowIndex-6) * 3600 + position.columnIndex * 60;
  const globalend = globalstart + valueMap[timelevel.value] * 60;
  if (timelevel.value === 0) {
    emit('update:hf', 1);
  } else {
    emit('update:hf', 0);
  }
  emit('update:globalstart', globalstart);
  emit('update:globalend', globalend);
}

// 同步订阅数据（云端与本地）
async function syncSubscribe (direction = 'upload') {
  const url = direction === 'upload' ? '/api/subscribe/upload' : '/api/subscribe/download';
  const data = direction === 'upload' ? { subscriptions: subscriptions.value } : {};
  const response = await request_data(url, props.server, data);
  if (direction === 'download' && response.subscriptions) {
    subscriptions.value = response.subscriptions;
    saveSubscriptions();
  }
}

// 监听 props.device、props.date 和 selectedChannels 的变化
watch(
  [() => props.device, () => props.date, () => selectedChannels.value],
  async ([newDevice, newDate, newItems], [oldDevice, oldDate, oldItems]) => {
    if (newDate !== '' && newDevice !== '' && (newDevice !== oldDevice || newDate !== oldDate)) {
      const response = await request_data(`/devicesnap/${newDevice}/${newDate}/`, props.server);
      channels.value = Object.keys(response);
      channel_datas.value = response;
      if (selectedSubscription.value === 'all' || selectedSubscription.value === '') {
        selectedChannels.value = [...channels.value];
      } else if (selectedSubscription.value !== 'save' && selectedSubscription.value !== 'saveas' && selectedSubscription.value !== 'delete') {
        handleSubscriptionChange(selectedSubscription.value);
      }
    }
    emit('update:selected', selectedChannels.value);
  },
  { immediate: true }
);

// 监听 selectedChannels 的变化，更新 localStorage
watch(selectedChannels, () => {
  saveSubscriptions();
});
</script>

<style scoped>
    /* 样式定义 */
    .toolbar {
        display: flex;
        align-items: center; /* 垂直居中 */
        justify-content: flex-start; /* 分布空间 */
        margin-bottom: 5px; /* 与下面内容的间隔 */
        font-size: 0.8em; /* 或者使用具体的像素值，如 '12px' */
        color:antiquewhite
    }

    .slider-container {
        display: flex;
        margin-left:10px;
        align-items: center; /* 垂直居中对齐滑条和标签 */
        gap: 5px; /* 元素之间的间距 */
        white-space: nowrap; /* 防止标签换行 */
    }

    /* 根据需要进一步调整滑条和开关的宽度 */
    .slider-container {
        flex-grow: 1; /* 滑条填充可用空间 */
        max-width: 400px; /* 最大宽度限制，根据实际情况调整 */
    }
    .checkbox-group {
        /*background-color: black;*/
        background-color: black;
        padding: 10px;
        display: flex;
        flex-wrap: wrap; /* 允许换行 */
        gap: 1px; /* 设置复选框之间以及行之间的间距 */
        max-height: 50px;
        height: auto;
        transition: max-height 0.5s ease;
    }
    .checkbox-group:hover {
      max-height: 500px;

    }
.small-divider {
  margin: 5px 0; /* 上下间隔为 5px */
}

    .checkbox-item {
        display: flex;
        align-items: center;
    }

        .checkbox-item label {
            cursor: pointer;
            margin-left: 2px; /* 标签和复选框之间的间距 */
            font-size: 0.8em; /* 或者使用具体的像素值，如 '12px' */
        }

    .device-snapshot {
        /* 由使用者决定宽度 */
        display: flex;
        flex-direction: column;
        height: 600px;
        background-color:black;
    }

    .heatmap {
        position: relative;
        background-color: black;
        flex-grow: 1; /* 让这个元素占据所有剩余的空间 */
    }
</style>
