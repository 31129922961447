<template>
  <div class="device-report-view">
    <!-- 顶部栏 -->
    <DeviceDateSelect
        v-model:selectedDevice="selectedDevice"
        v-model:selectedReportDate="selectedReportDate"
        :selections="props.selections"
    />
    <!-- 设备详细数据 -->
    <div>
    <!-- 动态生成顶层分组Tab -->
    <el-tabs v-model="selectedGroup" type="card" @tab-change="handleGroupChange">
      <el-tab-pane 
        v-for="(server, key) in serverGroups" 
        :key="key"
        :label="server.label" 
        :name="key"
      ></el-tab-pane>
    </el-tabs>

    <!-- 二级tab：当前分组下的子tab -->
    <el-tabs v-model="selectedTask">
      <el-tab-pane
        v-for="tab in groupTabs[selectedGroup]"
        :key="tab.name"
        :name="tab.name"
        :label="tab.label"
      ></el-tab-pane>
    </el-tabs>
  </div>
    <!-- 修改内容区域，使用动态组件 -->
    <div class="container" v-if="selectedTask.startsWith('数据-')">
      <div class="snapshot-wrapper">
        <el-card class="header-card" v-if="activetask && activetask.length>0">
          <div>进行中任务</div>
          <TreeViewObj :data="activetask"/>
        </el-card>
        <device-snapshot :key="taskkey" :device="selectedDevice" :date="selectedReportDate" 
                         v-model:globalstart="globalStart" v-model:globalend="globalEnd" 
                         v-model:hf="hf" v-model:selected="selectedChannels" :server="server"/>
        <el-card class="header-card">
          <div>设备快照</div>
          <TreeViewObj :data="current_device_detail"/>
        </el-card>
      </div>

      <div class="waveform-wrapper">
        <device-waveform :key="taskkey"  :device="selectedDevice" :date="selectedReportDate" mode="device" :globalstart="globalStart"
                       :globalend="globalEnd" :visible="selectedChannels" :hf="hf" :server="server"/>
      </div>
    </div>

    <!-- 统一处理所有报告视图 -->
    <div class="container" v-if="selectedTask.startsWith('报告-')">
      <div class="snapshot-wrapper">
        <el-card class="header-card" v-if="activetask && activetask.length>0">
          <div>进行中任务</div>
          <TreeViewObj :data="activetask"/>
        </el-card>
        <report-view :key="taskkey" :device="selectedDevice" :date="selectedReportDate" :server="server"/>
        <el-card class="header-card">
          <div>设备快照</div>
          <TreeViewObj :data="current_device_detail"/>
        </el-card>
      </div>
      <div class="waveform-wrapper">
        <device-waveform :key="taskkey" :device="selectedDevice" :date="selectedReportDate" mode="report" :server="server"/>
      </div>
    </div>

    <div class="container" v-if="selectedTask.startsWith('任务-')">
      <div class="tasks-view">
        <task-view
            :device="selectedDevice"
            :date="selectedReportDate"
            :serverType="server"
        />
      </div>
    </div>

    <div class="container" v-if="selectedTask.startsWith('历史-')">
      <div>
        <ReportHistory
            :device="selectedDevice"
            :date="selectedReportDate"
            :server="server"
        />
      </div>
    </div>

    <div class="container" v-if="selectedTask=='设备日志'">
      <div class="logs-view" style="width: 100%">
        <logs-view
            :device="selectedDevice"
            :date="selectedReportDate"
        />
      </div>
    </div>
    <div class="userReport" v-if="selectedTask=='用户报告'">
      <div>
        <ReportDetail v-model:device="selectedDevice" :selections="current_device_detail" :date="selectedReportDate"/>
      </div>
    </div>
    <div class="container" v-if="selectedTask=='补发任务'">
      <div>
        <ReuploadDetail v-model:device="selectedDevice"/>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, defineProps, watch, onMounted, onUnmounted, defineEmits, computed, defineAsyncComponent } from 'vue';

import DeviceDateSelect from '@/common/DeviceDateSelect.vue';
import DeviceSnapshot from './DeviceSnapshot.vue';
import ReportView from './ReportView.vue';
import ReportHistory from './ReportHistory.vue';
import TaskView from './TaskView.vue';
import DeviceWaveform from './DeviceWaveform.vue';
import LogsView from '@/components/LogsView.vue';
import { request_data, wdb_get_new } from '@/plugins/axios.js';
import { getDateTimestamp } from '@/plugins/timetool.js';
import TreeViewObj from '@/common/TreeViewObj.vue';
import ReportDetail from '@/components/ReportDetail.vue';
import ReuploadDetail from '@/components/ReuploadDetail.vue';
import { serverStore } from '@/config/servers'

// 接收prop
const props = defineProps({
  device: String,
  selections: Array
});
const selectedChannels = ref([]);

const emit = defineEmits(['update:device'])

// 顶层选中的分组（收集 / 计算1 / 计算2 / 实验）
const selectedGroup = ref('')

// 动态生成服务器分组
const serverGroups = computed(() => {
  const servers = serverStore.getAllServers()
  
  // 先将服务器分为收集和非收集两组
  const collectServer = servers.find(server => server.type === 'collect')
  const otherServers = servers.filter(server => server.type !== 'collect')
  
  // 按 order 排序其他服务器
  const sortedOtherServers = otherServers.sort((a, b) => a.order - b.order)
  
  // 合并结果
  const result = {}
  
  // 先添加收集服务器
  if (collectServer) {
    result[collectServer.key] = {
      name: collectServer.name,
      label: '数据收集/中控服务器'
    }
  }
  
  // 再添加其他服务器
  sortedOtherServers.forEach(server => {
    result[server.key] = {
      name: server.name,
      label: server.label
    }
  })
  
  return result
})

// 动态生成每个分组对应的tabs
const groupTabs = computed(() => {
  const tabs = {}
  const servers = serverStore.getAllServers()
  
  servers.forEach(server => {
    let baseTabs = []
    
    if (server.type === 'collect') {
      // 收集服务器的特殊配置
      baseTabs = [
        { name: `数据-${server.name}`, label: '原始数据' },
        { name: '报告-数据', label: '中控服务器报告' },
        { name: `任务-${server.name}`, label: '服务器任务' },
        { name: `历史-${server.name}`, label: '当日历史' },
        { name: '设备日志', label: '设备日志' },
        { name: '补发任务', label: '补发任务列表' },
        { name: '用户报告', label: '用户聚合报告' }
      ]
    } else {
      // 其他服务器的常规配置
      baseTabs = [
        { name: `数据-${server.name}`, label: '设备数据和特征' },
        { name: `报告-${server.name}`, label: '设备报告' },
        { name: `任务-${server.name}`, label: '设备日志' },
        { name: `历史-${server.name}`, label: '当日历史' }
      ]
    }
    
    tabs[server.key] = baseTabs
  })
  
  return tabs
})

// 当前选中的tab
const selectedTask = ref('')

// 设置初始值
watch(serverGroups, (newServerGroups) => {
  if (Object.keys(newServerGroups).length > 0 && !selectedGroup.value) {
    const firstServer = Object.keys(newServerGroups)[0]
    selectedGroup.value = firstServer
    if (groupTabs.value[firstServer]) {
      selectedTask.value = groupTabs.value[firstServer][0].name
    }
  }
}, { immediate: true })

// 切换分组时的处理函数
const handleGroupChange = (group) => {
  if (groupTabs.value[group]) {
    // 切换到新分组的第一个tab
    selectedTask.value = groupTabs.value[group][0].name
    // 增加taskkey触发组件刷新
    taskkey.value += 1
    // 重置一些状态
    selectedChannels.value = []
    globalStart.value = selectedReportDate.value ? 
      new Date(selectedReportDate.value + ' 00:00:00').getTime() / 1000 : 0
    globalEnd.value = globalStart.value + 3600
    // 强制刷新任务状态
    getTasks()
  }
}

// 添加对selectedTask的监听，当tab改变时也需要刷新
watch([() => selectedTask.value], () => {
  console.log('selectedTask', selectedTask.value, 'server', server.value)
  // 增加taskkey触发组件刷新
  taskkey.value += 1
  // 强制刷新任务状态
  getTasks()
});

const selectedDevice = ref(props.device)
const selectedReportDate = ref(''); // 选中的报告日期
const globalStart = ref(0)
const globalEnd = ref(0)
const hf = ref(0)
const current_device_detail = ref({})
const server = computed(() => {
  if (!selectedTask.value) return '收集'
  const [type, serverName] = selectedTask.value.split('-')
  // 如果是"报告-数据"，返回"收集"
  if (selectedTask.value === '报告-数据') return '收集'
  return serverName || '收集'
})
watch([() => selectedReportDate.value, () => selectedDevice.value], async ([newDate, newDevice], [oldDate, oldDevice]) => {
  if (newDevice === '' || newDevice !== oldDevice) {
    emit('update:device', newDevice); // 确保这段代码在正确的上下文中
  } else if (oldDate !== newDate) {
    globalStart.value = new Date(newDate + ' 00:00:00').getTime() / 1000;
    globalEnd.value = globalStart.value + 3600; // 假设是增加60
  }
}, { immediate: true });

let task_current = null
const activetask = ref([])

let requesting_task = 0
let last_active_task = 0;
const taskkey = ref(0)

async function getTasks () {
  if (!props.device || requesting_task) return
  requesting_task = 1

  const time = selectedReportDate.value ? getDateTimestamp(selectedReportDate.value) : null
  const detail = []
  current_device_detail.value = await request_data(`/devicedetail/${props.device}/`, server.value)
  task_current = await wdb_get_new(server.value, 'task', props.device, null, time)
  for (const task in task_current) {
    if (task_current[task] && (task_current[task].status === 'running' || task_current[task].status === 'notrun')) {
      detail.push(task_current[task])
    }
  }
  activetask.value = detail
  if (detail.length === 0 && last_active_task > 0) {
    taskkey.value += 1;
  }
  last_active_task = detail.length
  requesting_task = 0
}

let intervalId = null;
onMounted(async () => {
  intervalId = setInterval(getTasks, 3000);
});
onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});

// 添加组件选择逻辑
const getComponentForTask = (task) => {
  if (task.startsWith('数据-')) {
    return defineAsyncComponent(() => {
      return new Promise((resolve) => {
        resolve({
          template: `
            <div class="container">
              <div class="snapshot-wrapper">
                <el-card class="header-card" v-if="activetask && activetask.length>0">
                  <div>进行中任务</div>
                  <TreeViewObj :data="activetask"/>
                </el-card>
                <device-snapshot :key="taskkey" :device="device" :date="date" v-model:globalstart="globalStart"
                               v-model:globalend="globalEnd" v-model:hf="hf" v-model:selected="selectedChannels" :server="server"/>
                <el-card class="header-card">
                  <div>设备快照</div>
                  <TreeViewObj :data="current_device_detail"/>
                </el-card>
              </div>
              <div class="waveform-wrapper">
                <device-waveform :key="taskkey" :device="device" mode="device" :date="date" :globalstart="globalStart"
                               :globalend="globalEnd" :visible="selectedChannels" :hf="hf" :server="server"/>
              </div>
            </div>
          `
        })
      })
    })
  }
  // ... 其他组件类型的处理 ...
}

// 获取组件属性
const getComponentProps = (task) => {
  const baseProps = {
    device: selectedDevice.value,
    date: selectedReportDate.value,
    server: server.value
  }
  
  // 根据不同任务类型返回不同的props
  if (task.startsWith('数据-')) {
    return {
      ...baseProps,
      globalStart: globalStart.value,
      globalEnd: globalEnd.value,
      hf: hf.value,
      selectedChannels: selectedChannels.value
    }
  }
  
  return baseProps
}

</script>

<style scoped>

.container {
  display: flex;
  flex-direction: row; /* 水平布局 */
  width: 100%; /* 确保容器占满整个父元素宽度 */
}

.snapshot-wrapper {
  flex: 0 0 20%; /* flex-grow: 0, flex-shrink: 0, flex-basis: 20% */
  margin: 4px;
  height: 600px;
  /* 这里表示snapshot-wrapper不会随着容器大小的变化而增长或缩小，其基础大小为容器宽度的20% */
}

.waveform-wrapper {
  flex-grow: 1; /* 使用flex-grow: 1，意味着这个元素会占据剩余的空间 */
  /* 这里不需要设置flex-basis，因为默认值是0%，flex-grow会让它自动填满剩余空间 */
  margin: 4px;
}

.tasks-view {
  flex-grow: 1; /* 使用flex-grow: 1，意味着这个元素会占据剩余的空间 */
  /* 这里不需要设置flex-basis，因为默认值是0%，flex-grow会让它自动填满剩余空间 */
  margin: 4px;
}

.userReport {
  flex-direction: row; /* 水平布局 */
}

.spacer {
  height: 50px; /* 添加一个空白填充的高度，可以根据需要调整 */
}

</style>
