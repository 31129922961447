<template>
    <div class="toolbar">
        <div class="toolbar-container">
            设备:{{props.device}} 时间范围:{{formatTime(props.selstart)}} 到 {{formatTime(props.selend)}}
            <el-checkbox v-if="props.taskfilter === 'device'" v-model="isChecked" @change="emitCheckboxChange">
              实时跟踪
            </el-checkbox>
          </div>
        <div class="toolbar-container">
          <RemoteSelect v-model:selected="taskselected" db=".system_task" :item="props.taskfilter" :server="props.server"/>
          <span> 参数：</span>
          <el-select
            v-model="taskParam"
            filterable
            allow-create
            placeholder="请选择或输入参数"
            style="width: 120px;"
          >
            <el-option
              v-for="item in taskParamOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <el-button @click="submittask">执行任务</el-button>
            <el-select v-model="channelselected" :style="{ width: '180px' }" v-if="channels.length>0">
                <el-option v-for="option in channels"
                           :key="option"
                           :label="option"
                           :value="option">
                </el-option>
            </el-select>

           <el-button @click="channeldownload" v-if="channels.length>0">下载通道</el-button>
            <RemoteSelect  v-model:selected="fileselected" :style="{ width: '180px' }" :url="batchurl" :server="props.server" v-if="props.taskfilter != 'device'" />
            <el-button @click="filedownload" v-if="props.taskfilter!=='device'">下载数据</el-button>
        </div>
        <DownloadDialog :fileName="filename" v-model:dialogVisible="downloading" :downloadUrl="downloadurl" :server="props.server"></DownloadDialog>
    </div>
</template>

<script setup>
import { ref, defineProps, watch } from 'vue';
import { ElMessageBox } from 'element-plus';
import RemoteSelect from '@/common/RemoteSelect.vue';
import { formatDateTime, formatTime } from '@/plugins/timetool.js';
import { do_task } from '@/plugins/axios.js';
import DownloadDialog from '@/common/DownloadDialog.vue';
import {createAxiosInstance, wdb_get} from "@/plugins/axios";

const emit = defineEmits(['realtime']);
const taskParam = ref('');
const taskParamOptions = ref([]);

// 接收prop
const props = defineProps({
  selstart: Number,
  selend: Number,
  device: String,
  taskfilter: String,
  channels: Object,
  date: String,
  server: {
    type: String,
    default: '收集'
  }
});
const batchurl = ref('-');
const downloading = ref(false);
const downloadurl = ref('');
const taskselected = ref('');
const fileselected = ref('');
const channelselected = ref('');
// eslint-disable-next-line vue/no-dupe-keys
const channels = ref([]);
const filename = ref('');
const isChecked = ref(false);

const filedownload = async () => {
  if (props.taskfilter !== 'devicce' && fileselected.value) {
    ElMessageBox.confirm(`下载数据: ${fileselected.value}`, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning' // 对话框类型
    }).then(async () => { // 注意这里的 async
      // 用户点击了确定按钮
      console.log('Confirmed!');
      filename.value = fileselected.value
      if (props.taskfilter === 'psg') { downloadurl.value = 'download/?filename=' + fileselected.value + '&root=psgview&path=' + props.device } else { downloadurl.value = 'download/?filename=' + fileselected.value + '&root=batches&path=' + props.device }
      downloading.value = true;
    })
      .catch(() => {
        // 用户点击了取消按钮或关闭了对话框
        console.log('Canceled!');
      });
  }
}
const channeldownload = async () => {
  if (channelselected.value !== '') {
    ElMessageBox.confirm(`通道 ${channelselected.value},下载数据范围: ${formatTime(props.selstart)}到${formatTime(props.selend)}`, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning' // 对话框类型
    }).then(async () => { // 注意这里的 async
      // 用户点击了确定按钮
      console.log('Confirmed!');
      const fname = channelselected.value + '_' + props.device + '_' + formatDateTime(props.selstart) + '_' + formatDateTime(props.selend);
      if (channelselected.value === 'audio' || channelselected.value === '音频') { filename.value = fname + '.wav' } else { filename.value = fname + '.csv' }
      downloadurl.value = 'download_channel/?device=' + props.device + '&start=' + props.selstart + '&end=' + props.selend + '&task=' + channelselected.value + '&filter=' + props.taskfilter;

      downloading.value = true;
    })
      .catch(() => {
        // 用户点击了取消按钮或关闭了对话框
        console.log('Canceled!');
      });
  }
}

const emitCheckboxChange = () => {
  console.log('isChecked', isChecked.value)
  if (isChecked.value) {
    emit('realtime', true);
  } else {
    emit('realtime', false);
  }
}
const submittask = async () => {
  if (taskselected.value) {
    const task = {
      task: taskselected.value,
      start: props.selstart,
      end: props.selend,
      date: props.date,
      device: props.device,
      type: props.taskfilter,
      param: taskParam.value
    }
    const alertinfo = (task.type === 'device') ? `时间范围: ${formatTime(props.selstart)}到${formatTime(props.selend)} 任务:${taskselected.value}  参数:${taskParam.value}` : `任务:${taskselected.value} 参数:${taskParam.value}`;
    ElMessageBox.confirm(
      alertinfo,
      '执行任务', // 对话框的标题
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning' // 对话框类型
      }
    )
      .then(async () => { // 注意这里的 async
        // 用户点击了确定按钮
        console.log('Confirmed!');
        try {
          const response = await do_task('dotask', task, props.server);
          console.log(response)
          // 处理响应
        } catch (error) {
          console.error('任务执行失败', error);
        }
      })
      .catch(() => {
        // 用户点击了取消按钮或关闭了对话框
        console.log('Canceled!');
      });
  }
};

// 监听任务选择变化
watch(() => taskselected.value, async (newTask) => {
  if (newTask) {
    try {
      const axiosInstance = createAxiosInstance('收集');
      const response = await axiosInstance.get(`/taskparams/${encodeURIComponent(newTask)}`);
      if (response.data && response.data.data) {
        taskParamOptions.value = response.data.data;
      }
    } catch (error) {
      console.error('获取任务参数失败:', error);
      taskParamOptions.value = [];
    }
  }
});

watch([() => props.channels, () => props.device], ([newChannels, newDevice]) => {
  if (newChannels) {
    channels.value = Object.keys(newChannels);
    console.log('channels', channels.value)
  }
  if (newDevice) {
    if (props.taskfilter === 'psg') {
      batchurl.value = 'listdir/?root=psgview&path=' + encodeURIComponent(newDevice);
    } else { batchurl.value = 'listdir/?root=batches&path=' + encodeURIComponent(newDevice); }
    console.log('urls', batchurl.value)
  }
}, { immediate: true ,deep: true});

</script>
<style>
    /* 添加到你的组件的<style>标签内 */
    .toolbar-container {
        width:100%;

    }
    .toolbar {
        width: 100%;
      display: flex;
        align-items: center; /* 使子元素在垂直方向上居中 */
    }
</style>
