import axios from 'axios';

// 创建基础axios实例
const createBaseAxiosInstance = (baseURL) => {
  const token = localStorage.getItem('authToken');
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });
};

// 服务器管理API
export const serverApi = {
  // 获取所有服务器配置
  async getAllServers() {
    const instance = createBaseAxiosInstance('/a/');
    const response = await instance.get('/servers/');
    return response.data.data; // 假设API返回的数据结构中有data字段
  },
  // 添加新服务器
  async addServer(serverConfig) {
    const instance = createBaseAxiosInstance('/a/');
    const response = await instance.post('/servers/', serverConfig);
    return response.data;
  },
  // 获取脚本列表
  async getScripts() {
    const instance = createBaseAxiosInstance('/a/');
    const response = await instance.get('/scripts/');
    return response.data;
  },
  // 获取脚本内容
  async getScriptContent(path) {
    const instance = createBaseAxiosInstance('/a/');
    const response = await instance.get('/scripts/content/', {
      params: {
        path: encodeURIComponent(path)
      }
    });
    return response.data;
  },
  // 执行脚本
  async executeScript(path) {
    // const instance = createBaseAxiosInstance('/a/');
    // const response = await instance.post(`/scripts/execute/?path=${encodeURIComponent(path)}`);
    // 或者使用 params 方式
    // const response = await instance.post('/scripts/execute/', null, {
    //   params: { path }
    // });
    const response = {
      data: {
        message: 'success'
      }
    }
    return response.data;
  }
}; 