<template>
   <el-tabs model-value="first">
    <el-tab-pane label="Wdbview" name="first">
      <el-card shadow="always">
    <SystemConfig :selectedDbname="selectedDbname"
                  :selectedItem="selectedItem" :serverType="serverType"></SystemConfig>
    <div class="container">
      <div class="column dbnames">
        <h3>选择数据源</h3>
        <div>选择数据源</div>
        <el-select v-model="serverType" placeholder="请选择数据源" style="width: 150px;">
          <el-option 
            v-for="server in serverList" 
            :key="server.key"
            :label="server.name"
            :value="server.name"
          ></el-option>
        </el-select>
        <h3>数据库</h3>
        <ul class="scrollable">
          <li v-for="db in dbnames"
              :key="db"
              :class="{ selected: selectedDbname === db }"
              @click="handleDbClick(db)">
            {{ db }}
          </li>
        </ul>
      </div>
      <div class="column items">
        <h3>对象</h3>
        <ul class="scrollable">
          <li v-for="item in items"
              :key="item"
              @click="fetchData(item)"
              @contextmenu.prevent="openContextMenu($event, item)"
              :class="{ selected: selectedItem === item }">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="column datas">
        <div class="header">
          <h3>值</h3>
          <button class="edit-btn" v-if="datas" :disabled="datas.length === 0" @click="showEditor = true">修改</button>
        </div>
        <div class="scrollable">
          <TreeViewObj :data="datas" :expand="true"></TreeViewObj>
        </div>
      </div>
    </div>

    <JsonEdit
        v-model="showEditor"
        :show="showEditor"
        :datas="datas"
        :selectedDbname="selectedDbname"
        :selectedItem="selectedItem"
        :serverType="serverType"
        @update:show="showEditor = $event"
        @save="handleSave"
        @close="handleClose"
    />

    <!-- Context Menu -->
    <div v-if="showContextMenu"
         :style="{ top: `${contextMenuY}px`, left: `${contextMenuX}px` }"
         class="context-menu">
      <ul>
        <li @click="openDeleteConfirm(contextMenuSelectedItem)">删除{{ contextMenuSelectedItem }}</li>
      </ul>
    </div>

    <!-- Delete Confirmation Dialog -->
    <el-dialog
        title="确认删除"
        v-model="showDeleteConfirm"
        width="30%"
        @close="resetDeleteConfirmation">
      <span>您确定要删除该项吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetDeleteConfirmation">取消</el-button>
          <el-button type="primary" @click="confirmDeleteItem">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
      </el-tab-pane>
      <el-tab-pane label="Wdbsearch" name="second">
      <el-card shadow="always">
        <!-- 上部分：查询输入框 -->
        <div class="query-section">
          <el-input
            type="textarea"
            v-model="queryText"
            :rows="5"
            placeholder="请输入查询语句，例如：SELECT * FROM table_name WHERE condition"
            clearable
            class="query-input"
          />
          <el-button type="primary" @click="handleQuery" class="query-button">
            查询
          </el-button>
        </div>

        <!-- 下部分：左右两列布局 -->
        <div class="container">
          <!-- 左侧：对象 -->
          <div class="column objects">
            <h3>对象</h3>
            <ul class="scrollable">
              <li
                v-for="key in objectKeys"
                :key="key"
                :class="{ selected: selectedObject === key }"
                @click="handleObjectClick(key)"
              >
                {{ key }}
              </li>
            </ul>
          </div>
          <!-- 右侧：值 -->
          <div class="column values">
            <h3>值</h3>
             <TreeViewObj :data="selectedValue" :expand="true" />
          </div>
        </div>
      </el-card>
    </el-tab-pane>
   </el-tabs>

</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';

import { request_data, wdb_get_history_new, wdb_get_new } from '@/plugins/axios';
import TreeViewObj from '@/common/TreeViewObj.vue';
import SystemConfig from '@/components/SystemConfig.vue';
import JsonEdit from '@/components/JsonEdit.vue';
import { serverStore } from '@/config/servers'

// 响应式状态
const dbnames = ref([]);
const items = ref([]);
const datas = ref([]);
const selectedItem = ref('');
const selectedDbname = ref('');
const showEditor = ref(false);
const serverType = ref('收集');

// 上下文菜单状态
const showContextMenu = ref(false);
const contextMenuX = ref(0);
const contextMenuY = ref(0);
const contextMenuSelectedItem = ref('');

// 删除确认对话框状态
const showDeleteConfirm = ref(false);
const itemToDelete = ref(null);

const data = ref([]); // 原始数据

// 对象列表
const objectKeys = ref([]); // 所有的 key

const selectedObject = ref('');

// 当前选中的值
const selectedValue = ref(null);

// 定时器ID
let intervalId = null;

// 获取排序后的服务器列表
const serverList = computed(() => {
  return serverStore.getAllServers().sort((a, b) => a.order - b.order)
})

// 监听 serverList 变化，设置默认值
watch(serverList, (newList) => {
  if (newList.length > 0 && !serverType.value) {
    // 默认选择第一个服务器
    serverType.value = newList[0].name
  }
}, { immediate: true })

// 处理数据库点击
const handleDbClick = async (db) => {
  selectedDbname.value = db;
  await fetchItems(serverType.value);
  datas.value = [];
  selectedItem.value = '';
};

// 获取数据库列表
const fetchDbNames = async (serverType) => {
  try {
    dbnames.value = await wdb_get_new(serverType);
  } catch (error) {
    console.error('Error fetching database names:', error);
    dbnames.value = [];
  }
};

// 获取项目列表
const fetchItems = async (serverType) => {
  if (!selectedDbname.value) return;

  try {
    items.value = await wdb_get_new(serverType, selectedDbname.value);
  } catch (error) {
    console.error('Error fetching items:', error);
    items.value = [];
  }
};

// 获取数据
const fetchData = async (item) => {
  if (item !== undefined) {
    selectedItem.value = item;
  }
  if (selectedItem.value && !showEditor.value) {
    try {
      datas.value = await wdb_get_history_new(serverType.value, selectedDbname.value, selectedItem.value, 60);
    } catch (error) {
      console.error('Error fetching data:', error);
      datas.value = [];
    }
  }
};

// 编辑器相关方法
const handleSave = () => {
  showEditor.value = false;
  fetchData(selectedItem.value);
};

const handleClose = () => {
  showEditor.value = false;
  fetchData(selectedItem.value);
};

// 上下文菜单相关方法
const openContextMenu = (event, item) => {
  contextMenuX.value = event.clientX;
  contextMenuY.value = event.clientY;
  contextMenuSelectedItem.value = item;
  showContextMenu.value = true;
  document.addEventListener('click', closeContextMenu);
};

const closeContextMenu = () => {
  showContextMenu.value = false;
  document.removeEventListener('click', closeContextMenu);
};

// 删除相关方法
const openDeleteConfirm = (item) => {
  itemToDelete.value = item;
  showDeleteConfirm.value = true;
  showContextMenu.value = false;
};

const resetDeleteConfirmation = () => {
  showDeleteConfirm.value = false;
  itemToDelete.value = null;
};

const queryText = ref('');
const handleQuery = async () => {
  try {
    const response = await request_data('/wdbquery/?query=' + encodeURIComponent(queryText.value), serverType.value);
    if (response) {
      data.value = response; // 假设后端返回的就是一个 list
      objectKeys.value = response.map((item) => Object.keys(item)[0]); // 提取所有的 key
    }
    selectedObject.value = ''; // 清空选中项
    selectedValue.value = null; // 清空值
  } catch (error) {
    console.error('Error querying data:', error);
  }
};

const handleObjectClick = (key) => {
  selectedObject.value = key;

  // 查找对应的值
  const foundItem = data.value.find((item) => item[key]);
  selectedValue.value = foundItem ? foundItem[key] : null;
};
const confirmDeleteItem = async () => {
  try {
    await deleteItem(selectedDbname.value, itemToDelete.value);
    await fetchItems(serverType.value);
  } catch (error) {
    console.error('Error deleting item:', error);
  } finally {
    resetDeleteConfirmation();
  }
};

const deleteItem = async (table, item) => {
  return await request_data(`/delete/${table}/${item}/`);
};

// 生命周期钩子
onMounted(() => {
  fetchDbNames(serverType.value);

  intervalId = setInterval(() => {
    if (!showEditor.value) {
      fetchData(selectedItem.value);
    }
  }, 1000);
});

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});
</script>

<style scoped>
.container {
  display: flex;
  border: 1px solid #ebebeb;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.dbnames, .items {
  flex: 2.5;
  border-right: 2px solid #ebebeb;
}

.datas {
  flex: 5;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-btn {
  padding: 5px 10px;
  background-color: #409eff;
  color: white;
  border: none;
  cursor: pointer;
}

.scrollable {
  overflow-y: auto;
  max-height: 400px; /* 设置一个合适的高度以实现滚动 */
}

.column ul {
  list-style-type: none;
  padding: 0;
}

.column li {
  padding: 8px;
  cursor: pointer;
}

.column li:nth-child(odd) {
  background-color: #f8f9fa;
}

.column li:nth-child(even) {
  background-color: #ffffff;
}

.column li.selected {
  background-color: #409eff;
  color: white;
}

.context-menu {
  position: fixed;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.context-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.context-menu {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu li:hover {
  background-color: #eee;
}
.query-section {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.query-input {
  flex: 1;
}

.query-button {
  align-self: flex-start;
}

.container {
  display: flex;
  gap: 20px;
}

.column {
  flex: 1;
}

.scrollable {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
}

.selected {
  background-color: #f0f8ff;
}
</style>
