<template>
  <div class="upload-container">
    <!-- 自定义拖拽区域 -->
    <div class="drag-area" @dragover.prevent @drop="handleDrop">
      拖拽文件到这里。仅允许上传rml、edf、xml、rtf、docx、pdf文件，其余的将不允许上传
    </div>

    <!-- 选择目录和上传按钮 -->
    <div class="buttons">
      <el-button @click="selectFolder">选择目录</el-button>
      <el-button @click="submitUpload">上传</el-button>
      <el-button @click="clearAllFiles">清除</el-button>
    </div>

    <!-- 隐藏的文件选择输入，用于选择目录 -->
    <input ref="folderInput" type="file" webkitdirectory style="display: none" @change="handleFolderSelected"/>

    <!-- 文件列表表格 -->
    <el-table :data="fileList" style="width: 100%" max-height="300px">
      <el-table-column prop="filepath" label="目录"></el-table-column>
      <el-table-column prop="name" label="文件"></el-table-column>
      <el-table-column prop="size" label="大小" width="180">
        <template #default="scope">
          {{ formatSize(scope.row.size) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="上传状态" width="120">
        <template #default="scope">
          <el-tag :type="getTagType(scope.row.status)" :class="getStatusClass(scope.row.status)">
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template #default="scope">
          <!-- 删除单个文件按钮 -->
          <el-button size="small" @click="removeFile(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="uploadProgress.isVisible" class="progress">
      <p>{{ uploadProgress.message }} - {{ uploadProgress.currentFile }}</p>
      <el-progress
          :percentage="parseFloat((uploadProgress.completedCount / uploadProgress.totalCount * 100).toFixed(2))"></el-progress>
    </div>
  </div>
</template>

<style scoped>
.drag-area {
  /* 拖拽区域样式 */
}

.buttons {
  /* 按钮组样式 */
}

.upload-container {
  /* 上传组件容器样式 */
}
</style>

<script>
import {ref, computed, nextTick} from 'vue';
import {useStore} from 'vuex';
import {createAxiosInstance} from "@/plugins/axios";

export default {
  setup() {
    // const {proxy} = getCurrentInstance(); // 获取组件实例的代理对象
    const fileList = ref([]);
    const folderInput = ref(null);
    const store = useStore(); // 使用Vuex store
    const user = computed(() => store.state.user);
    const removeFile = (index) => {
      fileList.value.splice(index, 1);
    };
    const uploadProgress = ref({
      isVisible: false,
      currentFile: '',
      completedCount: 0,
      totalCount: 0,
      message: '',
    });

    const updateProgress = (currentFile, completedCount, totalCount, message) => {
      uploadProgress.value = {isVisible: true, currentFile, completedCount, totalCount, message};
    };

    const resetProgress = () => {
      uploadProgress.value = {isVisible: false, currentFile: '', completedCount: 0, totalCount: 0, message: ''};
    };
    const getStatusClass = (status) => {
      switch (status) {
        case '正在上传':
          return 'uploading_class'; // 返回一个标识“上传中”状态的类名
        default:
          return ''; // 其他状态不返回特定类名
      }
    };
    const getTagType = (status) => {
      switch (status) {
        case '上传失败':
          return 'danger'; // 红色，表示错误或危险
        case '正在上传':
          return 'warning'; // 黄色，表示正在进行或警告
        case '待上传':
          return 'info'; // 蓝色，表示一般信息
        case '已上传':
          return 'success'; // 绿色，表示成功
        default:
          return ''; // 默认，无特定类型，显示为灰色
      }
    };
    const submitUpload = async () => {
      let result = true;
      uploadProgress.value.isVisible = true; // 显示进度提示区域
      uploadProgress.value.totalCount = fileList.value.length; // 设置待上传文件总数
      uploadProgress.value.completedCount = 0; // 初始化已完成数
      for (let i = 0; i < fileList.value.length; i++) {
        const fileObj = fileList.value[i];
        const isLastInFolder = (i === fileList.value.length - 1) ||
            (fileList.value[i + 1].filepath != fileObj.filepath);
        uploadProgress.value.currentFile = fileObj.name; // 更新当前上传文件名
        result = await uploadFile(fileObj, isLastInFolder);
        if (result) {
          uploadProgress.value.completedCount++; // 更新已完成数
          console.log("upload successed " + fileObj.name)
        } else {
          console.log("upload failed " + fileObj.name)
          break;
        }
        updateProgress(fileObj.name, uploadProgress.value.completedCount, uploadProgress.value.totalCount, '正在上传中，关闭或刷新页面将停止上传。');
      }
      if (result) {
        updateProgress('', uploadProgress.value.completedCount, uploadProgress.value.totalCount, '数据已上传完成，正在分析中，请在下表中检查分析结果。');
      } else {
        updateProgress('', uploadProgress.value.completedCount, uploadProgress.value.totalCount, '文件上传失败，请检查失败的文件，刷新页面重新上传。');
      }
    };
    const uploadFile = async (fileObj, isLastInFolder, attempt = 1) => {
      const formData = new FormData();
      formData.append('file', fileObj.raw);
      formData.append('filename', fileObj.name);
      formData.append('filepath', fileObj.filepath);
      formData.append('uploader', user.value?.user || 'none');
      formData.append('over', isLastInFolder ? '1' : '0');

      try {
        fileObj.status = '正在上传';
        nextTick().then(() => {
          const uploadingFileRow = document.querySelector('.uploading_class');
          if (uploadingFileRow) {

            uploadingFileRow.scrollIntoView({behavior: 'smooth', block: 'nearest'});
          }
        });
        const axiosInstance = createAxiosInstance("收集");

        const response = await axiosInstance.post('/uploadpsgs/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            fileObj.status = `进度: ${progress.toFixed(2)}%`; // 使用toFixed(2)格式化进度值

            if (attempt > 5) {

              updateProgress(fileObj.name, uploadProgress.value.completedCount, uploadProgress.value.totalCount, '重连过多，如无法恢复请关闭或刷新页面重新上传。')

            }
          }
        });

        console.log(`文件 ${fileObj.name} 上传成功`, response.data);
        fileObj.status = '已上传';
        return true; // 上传成功，结束重试
      } catch (error) {
        console.error(`文件 ${fileObj.name} 上传失败`, error);
        fileObj.status = '上传失败';

        // 在重试之前，根据重试次数更新状态
        if (attempt >= 10) {
          fileObj.status = '当前文件重连次数过多，建议刷新页面重试';
        }

        // 无限重试，但是带有延迟
        setTimeout(() => uploadFile(fileObj, isLastInFolder, attempt + 1), 3000);
      }
      return false; // 如果捕获到异常，表示上传失败
    };


    // 清除所有文件
    const clearAllFiles = () => {
      fileList.value = [];
    };
    // const processFileEntry = (entry, path = '') => {
    //   return new Promise((resolve) => {
    //     entry.file(file => {
    //       const allowedTypes = ['rml', 'edf', 'xml', 'rtf', 'docx', 'pdf', 'klv'];
    //       const fileType = file.name.split('.').pop().toLowerCase();
    //
    //       if (allowedTypes.includes(fileType)) {
    //         const fileObj = {
    //           name: file.name,
    //           filepath: path,
    //           raw: file,
    //           status: '待上传',
    //           size: file.size,
    //         };
    //         resolve(fileObj); // 使用resolve返回fileObj
    //       } else {
    //         resolve(null); // 如果文件类型不匹配，解决Promise但不返回对象
    //       }
    //     });
    //   });
    // };
    const processFileEntry = (entry, path = '') => {
      return new Promise((resolve) => {
        entry.file(file => {
          const fileObj = {
            name: file.name,
            filepath: path,
            raw: file,
            status: '待上传',
            size: file.size,
          };
          resolve(fileObj); // 直接返回 fileObj，无需类型限制
        });
      });
    };
    const processDirectoryEntry = async (entry, path = '') => {
      return new Promise((resolve) => {
        const reader = entry.createReader();
        reader.readEntries(async (entries) => {
          const promises = [];
          for (const subEntry of entries) {
            if (subEntry.isFile) {
              const fileObj = await processFileEntry(subEntry, `${path}${entry.name}/`);
              if (fileObj) promises.push(fileObj);
            } else if (subEntry.isDirectory) {
              const subDirObjs = await processDirectoryEntry(subEntry, `${path}${entry.name}/`);
              promises.push(...subDirObjs);
            }
          }
          resolve(promises);
        });
      });
    };
    const handleDrop = async (event) => {
      event.preventDefault();
      resetProgress();
      clearCompletedFiles();
      const items = event.dataTransfer.items;
      const fileobjs = [];

      for (const item of items) {
        const entry = item.webkitGetAsEntry();
        if (entry) {
          if (entry.isFile) {
            const fileObj = await processFileEntry(entry);
            if (fileObj) fileobjs.push(fileObj);
          } else if (entry.isDirectory) {
            const dirFileObjs = await processDirectoryEntry(entry);
            fileobjs.push(...dirFileObjs);
          }
        }
      }

      fileList.value = fileobjs;
    };

    /*
    const handleDrop = async (event) => {
        event.preventDefault();
        resetProgress();
        const items = event.dataTransfer.items;
        clearCompletedFiles();
        const fileobjs = []
        const processEntry = async   (entry, path = '') => {
            if (entry.isFile) {
                entry.file(file => {
                    const allowedTypes = ['rml', 'edf', 'xml', 'rtf', 'docx', 'pdf'];
                    const fileType = file.name.split('.').pop().toLowerCase();

                    if (allowedTypes.includes(fileType)) {
                        const fileObj = {
                            name: file.name,
                            filepath: path,
                            raw: file, // 原始文件对象
                            status: '待上传', // 初始状态为待上传
                            size: file.size, // 文件大小
                        };
                        // 添加到文件列表中
                        fileobjs.push(fileObj);
                        console.log("add file")
                    }
                });
            } else if (entry.isDirectory) {
                const reader = entry.createReader();
                await reader.readEntries(entries => {
                    for (const subEntry of entries) {
                        processEntry(subEntry, `${path}${entry.name}/`);
                    }
                });
            }
        };
        for await  (const item of items) {
            const entry = item.webkitGetAsEntry();
            if (entry) {
                await processEntry(entry);
            }
        }
        fileList.value = fileobjs
        console.log("setver")


    };
    */

    const selectFolder = () => {
      // 触发文件夹选择
      folderInput.value.click();
    };

    // const handleFolderSelected = (event) => {
    //   clearCompletedFiles();
    //   resetProgress();
    //   const files = event.target.files; // 获取选择的文件列表
    //   const fileobjs = []
    //   for (const file of files) {
    //     const allowedTypes = ['rml', 'edf', 'xml', 'rtf', 'docx', 'pdf', 'klv'];
    //     const fileType = file.name.split('.').pop().toLowerCase();
    //
    //     if (allowedTypes.includes(fileType)) {
    //       const fileObj = {
    //         name: file.name,
    //         raw: file,
    //         filepath: file.webkitRelativePath.slice(0, file.webkitRelativePath.length - file.name.length),
    //         status: '待上传', // 初始状态为待上传
    //         size: file.size, // 文件大小
    //         // 可以根据需要添加其他属性，例如文件路径等
    //       };
    //       // 添加到文件列表中
    //       fileobjs.push(fileObj);
    //     }
    //   }
    //   fileList.value = fileobjs
    // };
    const handleFolderSelected = (event) => {
      clearCompletedFiles();
      resetProgress();
      const files = event.target.files; // Get the list of selected files
      const fileobjs = [];
      for (const file of files) {
        const fileObj = {
          name: file.name,
          raw: file,
          filepath: file.webkitRelativePath.slice(0, file.webkitRelativePath.length - file.name.length),
          status: '待上传', // Initial status is set to '待上传' (pending upload)
          size: file.size, // File size
          // Add other properties as needed, such as file path, etc.
        };
        // Add to the file list
        fileobjs.push(fileObj);
      }
      fileList.value = fileobjs;
    };
    const clearCompletedFiles = () => {
      fileList.value = fileList.value.filter(file => file.status !== '已上传');
    };


    const formatSize = (size) => {
      if (size < 1024) {
        return size + ' B';
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + ' KB';
      } else if (size < 1024 * 1024 * 1024) {
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
      } else {
        return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
      }
    };

    return {
      fileList,
      folderInput,
      handleDrop,
      selectFolder,
      handleFolderSelected,
      submitUpload,
      removeFile,
      clearAllFiles,

      formatSize,
      user,
      getTagType,
      getStatusClass,
      uploadProgress,
    };
  },
};
</script>

<style>
.drag-area {
  border: 2px dashed #ccc;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
}

.drag-area.highlight {
  border-color: #blue; /* 边框变亮的颜色 */
}

.buttons {
  display: flex;

  margin-bottom: 20px;
}


</style>
