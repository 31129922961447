<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';

import TreeViewObj from '@/common/TreeViewObj.vue'; // 确保路径正确
import { useStore } from 'vuex'

import { useRouter } from 'vue-router';

import CustomFilter from '@/common/CustomFilter.vue';
import { useJumpData } from '@/plugins/checkJumpData';
import { request_data } from '@/plugins/axios';
import DeviceTag from '@/components/DeviceTag.vue';
import DeviceReportView from './DeviceReportView.vue'
const { checkJumpData } = useJumpData();
const current_detail = ref('');

const router = useRouter();
const store = useStore();
// 定义响应式数据
const currentPage = ref(1);
const pageSize = ref(10);
const total = ref(100);
const rows = ref([]);

let intervalId = null; // 用于存储定时器ID
let encodedFilterStr = '';
const serverType = ref('收集')

// 跳转页面
function handleJump (type, value) {
  if (type === 'device' && value) {
    store.commit('setJumpData', {
      type: 'device',
      value,
      filter_type: 'input',
      path: '/device-management'
    })// 存储跳转信息，filter_type: tga 标签 inputs 搜索框

    router.push('/device-management');
  } else if (type === 'org') {
    router.push('/organization');
  }
}

// 从云端获取数据的方法
const fetchData = async () => {
  if (current_detail.value !== '') return
  try {
    const response = await request_data('/report/?filter=' + encodedFilterStr + '&page=' + currentPage.value, serverType.value)
    rows.value = response.nodes;
    total.value = response.count;
    console.log(encodedFilterStr);
  } catch (error) {
    console.error('获取数据失败:', error);
  }
};

async function navigateToReport (report, row) {
  const deviceid = row.info.device_id

  if (row.info && deviceid) {
    current_detail.value = deviceid
  }
}

// 处理响应数据

// 处理过滤器变化
const handleFilterChange = async (filterStr) => {
  encodedFilterStr = encodeURIComponent(filterStr);
  fetchData();
  // store.commit('setFilter', filterStr);
};

// 处理分页大小变化
const handleSizeChange = (newSize) => {
  pageSize.value = newSize;
  fetchData();
};

// 处理当前页变化
const handleCurrentChange = (val) => {
  currentPage.value = val;
  fetchData();
};

// 排序方法
const sortNames = (a, b) => {
  return (a.user + '').localeCompare(b.user + '');
};
onMounted(async () => {
  checkJumpData()

  fetchData()
  intervalId = setInterval(fetchData, 1000);
});
onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});

</script>

<template>
  <el-card shadow="always" v-show="current_detail == ''">
    <div>
      <CustomFilter item="report" @filter-changed="handleFilterChange"/>

    </div>

    <!-- 表格 -->
    <el-table :data="rows" table-layout="auto">
      <el-table-column type="selection" width="30"/>
      <el-table-column prop="report" label="编号" resizable sortable :sort-method="sortNames">
        <template #default="scope">
            <el-tooltip placement="right" effect="light">
                <template #content>
                  <div class="tooltip-content">
                    <TreeViewObj :data="scope.row"></TreeViewObj>
                  </div>
                </template>
                <el-tag type="info" size="small" effect="dark">动态</el-tag>
              </el-tooltip>
          <el-tag @click="navigateToReport(scope.row.device_id, scope.row)">详情</el-tag>
          {{ scope.row.info.device_id}}@{{ scope.row.info.date}}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="质量">
         <template #default="scope">
              <DeviceTag :info="{device:scope.row['taginfo']['质量']}"/>
            </template>
      </el-table-column>
      <el-table-column prop="status" label="异常">
        <template #default="scope">
          <DeviceTag :info="{ device:scope.row['taginfo']['异常'] }"/>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="指标">
        <template #default="scope">
          <DeviceTag :info="{ device:scope.row['taginfo']['指标'] }"/>
        </template></el-table-column>
    </el-table>

    <el-pagination
        v-model:current-page="currentPage"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    ></el-pagination>
  </el-card>
  <div v-if="current_detail!=''">
        <DeviceReportView v-model:device="current_detail" :selections="rows"/>
      </div>
</template>

<style scoped>
.add-user-form .el-form-item {
  margin-bottom: 24px; /* 调整表单项间的间距 */
}

.add-user-form .el-input,
.add-user-form .el-select {
  width: 100%; /* 调整输入框和选择框的宽度 */
}

.report {
  height: 100vh; /* 可以根据需要调整这个高度 */
  overflow-y: auto;
  overflow-x: hidden;
}

.device-jump-tag {
  color: #409eff;
  cursor: pointer;
}

.tooltip-content {
  max-width: 200px;
  word-break: break-word;
}
</style>
