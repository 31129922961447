<template>
  <!-- 设备详细数据 -->
  <el-card>
    <div class="header-content sticky-header" v-if="stage !== 2">
      <!-- 日期选择下拉框 -->
      <el-select v-model="selectedReportDate" placeholder="选择报告日期" style="width: 150px;">
        <el-option
            v-for="date in reportDates"
            :key="date"
            :label="date"
            :value="date">
        </el-option>
      </el-select>
      <el-button
          @click="handleClose"
          type="primary"
          v-if="stage === 3">
        返回列表页
      </el-button>
      <el-select placeholder="下载报告" style="margin-left: 10px;width: 100px" v-if="special">
        <el-option @click="downloadExcel('day')" label="下载当日" value="today"></el-option>
        <el-option @click="downloadExcel('history')" label="下载历史" value="all"></el-option>
        <el-option @click="downloadExcel('all')" label="下载当日所有" value="all"></el-option>
      </el-select>
    </div>
    <div class="report-container">
      <h1 class="report-title">睡眠报告</h1>
      <div class="report-section" v-if="isShenzhenHospital!=='1'">
        <h4>用户信息</h4>
        <table class="user-info-table">
          <tr>
            <td> 姓名: {{ userinfo.username }}</td>
            <td> 手机号: {{ userinfo.phoneNumber }}</td>
            <td> 性别: {{ userinfo.sex }}</td>
          </tr>
        </table>
      </div>
      <div class="report-section">
        <h4>睡眠分析</h4>
        <table class="data-table">
          <tr>
            <td>总睡眠时长</td>
            <td class="right-align">
              <span>{{ formatMinutes(data.tst) }}</span>
              <span :style="getSpanStyle(data,data.tst_detail, 'tst')">{{
                  getTagOrTag2(data, data.tst_detail, 'tst').name
                }}</span>
            </td>
          </tr>
          <tr>
            <td>睡眠效率</td>
            <td class="right-align">
              <span>{{ getSleepEfficiency(data.tst, data.tib) }}%</span>
              <span :style="getSpanStyle(data,getSleepEfficiency(data.tst, data.tib), 'se')">{{
                  getTagOrTag2(data, getSleepEfficiency(data.tst, data.tib), 'se').name
                }}</span>
            </td>
          </tr>
          <tr>
            <td>入睡速度</td>
            <td class="right-align">
              <span>{{ formatMinutes(data.sol) }}</span>
              <span :style="getSpanStyle(data,data.sol_detail,'sol')">{{
                  getTagOrTag2(data, data.sol_detail, 'sol').name
                }}</span>
            </td>
          </tr>
          <tr>
            <td>睡眠中断</td>
            <td class="right-align">
              <span>{{ formatMinutes(data.waso) }}</span>
              <span :style="getSpanStyle(data,data.waso_detail,'waso')">{{
                  getTagOrTag2(data, data.waso_detail, 'waso').name
                }}</span>
            </td>
          </tr>
          <tr>
            <td>深睡眠</td>
            <td class="right-align">
              <span>{{ formatMinutes(data.deep_time) }}</span>
              <span :style="getSpanStyle(data,data.deep_time_detail,'dst')">{{
                  getTagOrTag2(data, data.deep_time_detail, 'dst').name
                }}</span>
            </td>
          </tr>
          <tr>
            <td>快速眼动睡眠</td>
            <td class="right-align">
              <span>{{ formatMinutes(data.rem_time) }}</span>
              <span :style="getSpanStyle(data,data.rem_time_detail,'rem')">{{
                  getTagOrTag2(data, data.rem_time_detail, 'rem').name
                }}</span>
            </td>
          </tr>
          <tr>
            <td>睡眠规律性</td>
            <td class="right-align">
              <span>{{ data.sri !== -1 ? `${Math.round(data.sri)}%` : '-' }}</span>
              <span :style="getSpanStyle(data,data.sri_detail, 'sri')">{{
                  getTagOrTag2(data, data.sri_detail, 'sri').name
                }}</span>
            </td>
          </tr>
          <tr>
            <td>睡眠碎片化，SFI</td>
            <td class="right-align">
              <span>{{ data.sfi !== -1 ? `${data.sfi}` : '-' }}</span>

            </td>
          </tr>
        </table>
      </div>
      <!--睡眠结构 -->

      <div class="report-section">
        <h4>睡眠结构</h4>
        <div :id="chartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        <div :id="ArtChartId"
             style="width: 80%; height: 80px; background-color: white;margin-top: 5px;margin-bottom: 5px"></div>

        <table class="data-table">
          <tr>
            <td>清醒</td>
            <td>{{ formatMinutes(data.sleep_dur_1) }}</td>
          </tr>
          <tr>
            <td>快速眼动睡眠</td>
            <td>{{ formatMinutes(summmary.rem_sleep_time) }}（{{
                Math.round(parseFloat(summmary.rem_efficiency) * 100)
              }}%）
            </td>
          </tr>
          <tr>
            <td>浅睡眠</td>
            <td>{{
                formatMinutes(summmary.core_sleep_time)
              }}（{{ Math.round(parseFloat(summmary.core_sleep_efficiency) * 100) }}%）
            </td>
          </tr>
          <tr>
            <td>深睡眠</td>
            <td>{{
                formatMinutes(summmary.deep_sleep_time)
              }}（{{ Math.round(parseFloat(summmary.deep_sleep_efficiency) * 100) }}%）
            </td>
          </tr>
        </table>
      </div>

      <!-- 添加小睡部分 -->
      <div class="report-section" v-if="napList.length > 0">
        <h4>小睡记录</h4>
        <div class="nap-charts">
          <div v-for="(nap, index) in napList" :key="index" class="nap-item">
            <div class="nap-header">
              <h3>小睡 {{ index + 1 }}</h3>
              <div class="nap-time">
                {{ formatTime(nap.start) }} - {{ formatTime(nap.end) }}
              </div>
            </div>
            
            <div :id="`nap-stage-chart-${index}`" class="chart"></div>
            <!-- <div :id="`nap-hr-chart-${index}`" class="chart"></div>
            <div :id="`nap-rr-chart-${index}`" class="chart"></div>
            <div :id="`nap-hrv-chart-${index}`" class="chart"></div> -->
          </div>
        </div>
      </div>
      <!--呼吸健康 -->
      <div class="report-section">
        <h4> 呼吸健康</h4>
        <h5>呼吸暂停低通气指数(AHI)</h5>
        <table class="data-table">
          <tr>
            <td>呼吸暂停低通指数,AHI</td>
            <td>{{ parseFloat(data.ahi).toFixed(1) }}</td>
          </tr>
          <tr>
            <td>NREM AHI</td>
            <td>{{ parseFloat(data.ahi_345).toFixed(1) }}</td>
          </tr>
          <tr>
            <td>REM AHI</td>
            <td>{{ parseFloat(data.ahi_2).toFixed(1) }}</td>
          </tr>
          <tr>
            <td>鼾声指数,SI</td>
            <td>{{ parseFloat(data.bcgsnore_index).toFixed(1) }}</td>
          </tr>
          <tr>
            <td>睡眠平均呼吸率</td>
            <td>{{ Math.round(parseFloat(data.avg_resp)).toFixed(1) }}次/分</td>
          </tr>
        </table>
        <div :id="ahiChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px;margin-left: -2%">
        </div>
        <div :id="AhChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        <!--睡眠生理指标 -->
        <div class="report-section">
          <h4> 睡眠期生理指标</h4>
          <table class="data-table">
            <tr>
              <td>睡眠最低心率</td>
              <td>{{ summmary.min_sleep_heart_rate }}次/分</td>
            </tr>
            <tr>
              <td>睡眠平均心率</td>
              <td>{{ summmary.avg_sleep_heart_rate }}次/分</td>
            </tr>
            <tr>
              <td>睡眠期HRV_RMSSD</td>
              <td>{{ summmary.avg_hrv_rmssd }}毫秒</td>
            </tr>
            <tr>
              <td>睡眠期HRV_LF/HF</td>
              <td>{{ summmary.avg_hrv_radio }}</td>
            </tr>


          </table>
          <h5>睡眠心率</h5>
          <div :id="hrBLChartId"
               style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
          <div :id="HrChartId"
               style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
          <h5>睡眠心率变异性</h5>

          <div :id="hrvBLChartId"
               style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
          <div :id="HrvChartId"
               style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
          <h5>睡眠呼吸频率</h5>

          <div :id="rrBLChartId"
               style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        </div>

        <div :id="RrChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
      </div>
      <h5>血氧</h5>

      <div :id="spo2ChartId"
           style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px;margin-left: -2%">
      </div>
      <!--睡眠环境 -->

      <div class="report-section">
        <h4>睡眠环境</h4>
        <table class="data-table">
          <tr>
            <td>平均温度</td>
            <td>{{ Math.round(parseFloat(endData.平均温度)) }}°C</td>
          </tr>
          <tr>
            <td>平均湿度</td>
            <td>{{ Math.round(parseFloat(endData.平均湿度)) }}%</td>
          </tr>
          <tr>
            <td>光照影响的睡眠比例</td>
            <td>{{ Math.round(parseFloat(summmary.high_illuminance) * 100) }}%</td>
          </tr>
        </table>
        <div :id="EnvChartId"
             style="width: 80%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>

      </div>

    </div>
  </el-card>
</template>

<script setup>

import { defineProps, ref, watch, defineEmits, onMounted, nextTick, onUnmounted } from 'vue';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { createAxiosInstance, request_data } from '@/plugins/axios';
import * as echarts from 'echarts';
import { setStageChartOption } from '@/plugins/chart/stage_chart';
import { setArtChartOption } from '@/plugins/chart/day_art_chart';
import { setArt2ChartOption } from '@/plugins/chart/day_art_chart2';
import { setDayEnvChartOption } from '@/plugins/chart/day_env_chart';
import { setDayHRVChartOption } from '@/plugins/chart/day_hrv_chart';
import { setDayRRChartOption } from '@/plugins/chart/day_rr_chart';
import { setStageAHChartOption } from '@/plugins/chart/stage_ah_chart';
import { setDayHRChartOption } from '@/plugins/chart/day_hr_chart';
import { setStageAH2ChartOption } from '@/plugins/chart/stage_ah_chart2';
import { setRRBaselineChartOption } from '@/plugins/chart/rr_baseline_chart';
import { setHRBaselineChartOption } from '@/plugins/chart/hr_baseline_chart';
import { setHRVBaselineChartOption } from '@/plugins/chart/hrv_baseline_chart';
import { setAHIChartOption } from '@/plugins/chart/ahi_color_chart';
import { chart_dates, resetDates } from '@/plugins/chart/variables';
import { formatDateString } from '@/plugins/utils/utils';
import { setSpO2ChartOption } from '@/plugins/chart/spo2_chart';
import { setNapStageChartOption } from '@/plugins/chart/nap_stage_chart';
import { setDayNapHRChartOption } from '@/plugins/chart/day_nap_hr_chart';
import { setDayNapRRChartOption } from '@/plugins/chart/day_nap_rr_chart';
import { setDayNapHRVChartOption } from '@/plugins/chart/day_nap_hrv_chart';

const emit = defineEmits(['update:device'])

const props = defineProps({
  device: String,
  selections: Array,
  date: String,
  stage: String,
  special: String,
  isShenzhenHospital: String

});
const stage = ref(props.stage)
const selectedDevice = ref(props.device)
const selections = ref(props.selections)
const isShenzhenHospital = ref(props.isShenzhenHospital)
const userinfo = ref({
  username: '',
  phoneNumber: '',
  sex: ''
});
const deviceinfo = ref(selections.value.deviceinfo)
const selectedReportDate = ref(props.date); // 选中的报告日期
const reports = ref({}); // 报告列表
const reportDates = ref([]); // 报告日期列表
const keyMap = {
  reporttime: '报告开始计算时间',
  costtime: '报告生成总耗时',
  startc: '数据开始时间',
  endc: '数据结束时间',
  dur: '数据总时长',
  snore_index: '打鼾指数',
  snore_index_onback: '仰卧打鼾指数',
  snore_index_onside: '侧卧打鼾指数',
  posion_onback_time: '仰卧时长',
  posion_onback_rate: '仰卧占比',
  posion_onside_time: '侧卧时长',
  posion_onside_rate: '侧卧占比',

  sigtype_0: '无信号',
  sigtype_1: '低信号',
  sigtype_2: '正常信号',
  sigtype_3: '微体动信号',
  sigtype_4: '强体动信号',
  onbedlevel: '在床阈值',
  err_log: '错误信息',
  avg_temp: '平均温度',
  avg_humi: '平均湿度',
  avg_illu: '睡眠时平均光照',
  high_illu: '强光照睡眠',
  sleep_time: '有效睡眠总时间',
  ahi_count: '呼吸事件总数',
  ahi: '综合AHI',
  long_artifact: '体动次数',
  short_artifact: '微体动次数',
  sri: 'SRI',
  quality: '睡眠质量分',
  qualitylogs: '睡眠质量细则',
  sleep_dur_0: '睡眠分期_体动和无效时长',
  ahi_count_0: '体动呼吸事件次数（无效）',
  sleep_dur_1: '睡眠分期_Wake时长',
  ahi_count_1: 'Wake呼吸事件次数（无效）',
  sleep_dur_2: '睡眠分期_REM时长',
  ahi_count_2: 'REM期呼吸事件次数',
  ahi_2: 'REM期呼吸指数',
  sleep_dur_3: '睡眠分期_N1时长',
  sleep_dur_4: '睡眠分期_N2时长',
  sleep_dur_5: '睡眠分期_N3时长',
  ahi_count_345: 'NREM期呼吸事件次数',
  ahi_345: 'NREM期呼吸指数',
  odi_count: 'ODI次数',
  odi: 'ODI指数',
  min_spo2: '最低血氧饱和度',
  mean_spo2: '平均血氧饱和度',
  hrv_rmssd_mean: 'hrv_rmssd',
  hrv_morning: 'hrv_morning',
  hrv_evening: 'hrv_evening',
  hrv_delta: 'hrv_delta',
  hrv_ratio_mean: 'hrv_ratio',
  sleep_range: '睡眠区间调试信息',
  push_message: 'push消息'
};
const endData = ref({})
const data = ref({})
const summmary = ref({})
const stage_data = {}
const art_data = {}
const env_data = {}
const hrv_data = {}
const rr_data = {}
const hr_data = {}
const spo2_data = {}
const chartId = ref(`stage_chart_${Math.random().toString(36).substr(2, 9)}`);
const ArtChartId = ref(`art_chart_${Math.random().toString(36).substr(2, 9)}`);
const EnvChartId = ref(`env_chart_${Math.random().toString(36).substr(2, 9)}`);
const HrvChartId = ref(`hrv_chart_${Math.random().toString(36).substr(2, 9)}`);
const RrChartId = ref(`rr_chart_${Math.random().toString(36).substr(2, 9)}`);
const AhChartId = ref(`ah_chart_${Math.random().toString(36).substr(2, 9)}`);
const HrChartId = ref(`hr_chart_${Math.random().toString(36).substr(2, 9)}`);
const rrBLChartId = ref(`rr_bl_chart_${Math.random().toString(36).substr(2, 9)}`);
const hrBLChartId = ref(`hr_bl_chart_${Math.random().toString(36).substr(2, 9)}`);
const hrvBLChartId = ref(`hrv_bl_chart_${Math.random().toString(36).substr(2, 9)}`);
const ahiChartId = ref(`ahi_chart_${Math.random().toString(36).substr(2, 9)}`);
const spo2ChartId = ref(`spo2_chart_${Math.random().toString(36).substr(2, 9)}`);
const reports_data = ref([])

// 添加小睡相关的响应式变量
const napList = ref([])
const chartInstances = ref({})

// 验证小睡数据是否有效
const validateNapData = (report) => {
  return report?.nap_range?.length > 0 
    && report?.nap_range?.length === report?.nap_stages5?.length
    && report?.nap_range?.length === report?.nap_resp_series?.length
    && report?.nap_range?.length === report?.nap_bpm_series?.length
    && report?.nap_range?.length === report?.nap_hrv_rmssd?.length
}

// 处理小睡数据
const processNapData = (report) => {
  if (!validateNapData(report)) return []
  
  return report.nap_range.map((range, index) => {
    const start = range.s + report.start
    const end = range.e + report.start
    
    return {
      index,
      start,
      end,
      stageData: {
        start,
        end,
        stages: report.nap_stages5[index]
      },
      hrData: {
        start,
        end,
        stage: report.nap_stages5[index],
        avg: report.nap_hr[index].avg,
        segments: report.nap_bpm_series[index]
      },
      rrData: {
        start,
        end,
        stage: report.nap_stages5[index],
        avg: report.nap_resp[index].avg,
        segments: report.nap_resp_series[index]
      },
      hrvData: {
        start,
        end,
        stage: report.nap_stages5[index],
        avg: report.nap_hrv[index].avg,
        segments: report.nap_hrv_rmssd[index]
      }
    }
  })
}

// 格式化时间显示
const formatTime = (timestamp) => {
  const date = new Date(timestamp * 1000)
  return date.toLocaleTimeString('zh-CN', { 
    hour: '2-digit', 
    minute: '2-digit' 
  })
}

// 初始化小睡图表
const initNapCharts = (napData) => {
  napData.forEach((nap, index) => {
    const charts = {
      stage: echarts.init(document.getElementById(`nap-stage-chart-${index}`)),
      // hr: echarts.init(document.getElementById(`nap-hr-chart-${index}`)),
      // rr: echarts.init(document.getElementById(`nap-rr-chart-${index}`)),
      // hrv: echarts.init(document.getElementById(`nap-hrv-chart-${index}`))
    }
    console.log(nap.stageData)
    // charts.stage.setOption(setNapStageChartOption(nap.stageData))
    // charts.hr.setOption(setDayNapHRChartOption(nap.hrData))
    // charts.rr.setOption(setDayNapRRChartOption(nap.rrData))
    // charts.hrv.setOption(setDayNapHRVChartOption(nap.hrvData))
    const canvas = document.getElementById(`nap-stage-chart-${index}`)
    const chart = echarts.init(canvas);
    setNapStageChartOption(nap.stageData,chart);
    return chart;
    // chartInstances.value[index] = charts
  })
}

// 计算总时长
function formatMinutes (minutes) {
  if (Number.isFinite(minutes)) { // 检查minutes是否为一个有限的数
    const roundedMinutes = Math.round(minutes);
    const hours = Math.floor(roundedMinutes / 60);
    const remainingMinutes = roundedMinutes % 60;

    if (hours === 0) {
      return `${remainingMinutes} 分钟`;
    }

    return `${hours} 时 ${remainingMinutes} 分`;
  }
  return '';
}

// 更新数据
function updateEndData () {
  const mappedData = {};
  data.value = reports.value.online
  // 分配映射的数据
  if (data.value) {
    Object.keys(keyMap).forEach(key => {
      if (key in data.value) {
        mappedData[keyMap[key]] = data.value[key];
      }
    })
  }
  // 添加'其他'分类
  endData.value = mappedData;
}

// 监听日期 选择的被试
watch([() => selectedReportDate.value, () => selectedDevice.value], async ([newDate, newDevice], [oldDate, oldDevice]) => {
  if (newDevice === '' || newDevice !== oldDevice) {
    emit('update:device', newDevice); // 确保这段代码在正确的上下文中
  } else if (oldDate !== newDate) {
    getReports()
    doRender()
  }
}, { immediate: true });
// 监听日期
watch(() => props.date, (newVal) => {
  // 当数据变化时，重新渲染图表
  selectedReportDate.value = newVal

  getReports()
  doRender()
}, { deep: true });
watch(() => props.device, (newVal) => {
  // 当数据变化时，重新渲染图表
  selectedDevice.value = newVal
  changeDate()
  getReports()
  doRender()
}, { deep: true });
watch(() => props.selections, (newVal) => {
  // 当数据变化时，重新渲染图表
  selections.value = newVal
  if (selections.value?.userinfo) {  // 添加空值检查
    userinfo.value = selections.value.userinfo
  }
  if (selections.value?.deviceinfo) {  // 添加空值检查
    deviceinfo.value = selections.value.deviceinfo
  }
  getReports()
  doRender()
}, { deep: true });

async function getReportList (reportdate) {
  // 将 selectedDate 转换为 Date 对象
  const selectedDate = new Date(reportdate);
  // 计算起始日期和结束日期
  const endDate = new Date(selectedDate);
  const startDate = new Date(selectedDate);
  startDate.setDate(selectedDate.getDate() - 13);

  // 格式化日期为 yyyy-mm-dd
  const formattedStartDate = startDate.toISOString().split('T')[0];
  const formattedEndDate = endDate.toISOString().split('T')[0];

  // 遍历前 13 天加上选定的日期，共 14 天
  const response = await request_data(`/deviceallreport/${selectedDevice.value}/${formattedStartDate}/${formattedEndDate}/`);
  // 将数据赋值给 reports.value

  reports_data.value = response;
}

// 获取 report 数据；画图
async function getReports () {
  if (!props.device) return
  const reportdate = selectedReportDate.value ? selectedReportDate.value : '_';
  reports.value = await request_data(`/devicereport/${selectedDevice.value}/${reportdate}/`);
  await getReportList(selectedReportDate.value)

  if (reports.value.summary) {
    summmary.value = reports.value.summary
    updateEndData()
    doRender()
    doAhChart()
    doArtChart()
    doEnvChart()
    doHrvChart()
    doRrChart()
    doHrChart()
    doRrBlChart()
    doHrBlChart()
    doHrvBlChart()
    doAhiChart()
    doSpo2Chart()
    
    // 处理小睡数据
    napList.value = processNapData(reports.value.online)
    nextTick(() => {
      initNapCharts(napList.value)
    })
  }
}

// 返回列表
const handleClose = () => {
  selectedDevice.value = ''
};

async function changeDate () {
  reportDates.value = []
  selectedReportDate.value = ''
  const response = await request_data(`/list_reports/${selectedDevice.value}/`);
  reportDates.value = response.nodes;
  if (response.nodes.length > 0 && selectedReportDate.value === '') {
    selectedReportDate.value = response.nodes[0];
    getReports()
  }
  return response
}

// 画图
function doRender () {
  stage_data.start = data.value.starttime; // starttime 字段
  stage_data.end = data.value.endtime; // endtime 字段
  stage_data.stages = data.value.stage_details// stage_details
  stage_data.nointention = (data.value.intention_middle && data.value.intention_middle.length > 0) ? data.value.intention_middle : null
  stage_data.leavebed = (data.value.leavebed && data.value.leavebed.length > 0) ? data.value.leavebed : null
  const canvas = document.getElementById(chartId.value)
  const chart = echarts.init(canvas);
  setStageChartOption(chart, stage_data);
  return chart;
}

// 体动
function doArtChart () {
  const art_canvas = document.getElementById(ArtChartId.value)
  const art_chart = echarts.init(art_canvas);
  art_data.start = data.value.starttime; // starttime 字段
  art_data.end = data.value.endtime; // endtime 字段
  art_data.leavebed = (data.value.leavebed && data.value.leavebed.length > 0) ? data.value.leavebed : null

  if (data.value.artifact_series) {
    art_data.segments = data.value.artifact_series
    art_data.stages = data.value.stage_details// stage_details
    setArt2ChartOption(art_chart, art_data)
  } else {
    art_data.segments = data.value.artifact_events
    setArtChartOption(art_chart, art_data);
  }

  return art_chart;
}

// 环境
function doEnvChart () {
  env_data.start = data.value.starttime; // starttime 字段
  env_data.end = data.value.endtime; // endtime 字段
  // env_data.intention = data.value['sleep_intention']// stage_details
  env_data.nointention = data.value.intention_middle// stage_details
  env_data.leavebed = data.value.leavebed
  // env_data.leave = data.value['sleep_leave']
  env_data.segments = data.value.thi_series
  const env_canvas = document.getElementById(EnvChartId.value)
  const env_chart = echarts.init(env_canvas);
  setDayEnvChartOption(env_chart, env_data);
  return env_chart;
}

function doSpo2Chart () {
  spo2_data.start = data.value.starttime; // starttime 字段
  spo2_data.end = data.value.endtime; // endtime 字段
  spo2_data.stage = data.value.stage_details// stage_details
  spo2_data.spo2 = data.value.spo2_data

  const spo2_canvas = document.getElementById(spo2ChartId.value)
  const spo2_chart = echarts.init(spo2_canvas);
  setSpO2ChartOption(spo2_chart, spo2_data);
  return spo2_chart;
}

function doHrvChart () {
  hrv_data.start = data.value.starttime; // starttime 字段
  hrv_data.end = data.value.endtime; // endtime 字段
  hrv_data.nointention = data.value.intention_middle
  hrv_data.leavebed = data.value.leavebed
  // hrv_data.intention = data.value['sleep_intention']
  // hrv_data.leave = data.value['sleep_leave']
  hrv_data.segments = data.value.hrv_rmssd
  hrv_data.avg = data.value.hrv_rmssd_mean
  const hrv_canvas = document.getElementById(HrvChartId.value)
  const hrv_chart = echarts.init(hrv_canvas);
  setDayHRVChartOption(hrv_chart, hrv_data);
  return hrv_chart;
}

function doRrChart () {
  rr_data.start = data.value.starttime; // starttime 字段
  rr_data.end = data.value.endtime; // endtime 字段
  // rr_data.intention = data.value['sleep_intention']// stage_details
  rr_data.nointention = data.value.intention_middle// stage_details
  // rr_data.leave = data.value['sleep_leave']
  rr_data.leavebed = data.value.leavebed
  rr_data.segments = data.value.resp_series
  rr_data.avg = data.value.avg_resp
  const rr_canvas = document.getElementById(RrChartId.value)
  const rr_chart = echarts.init(rr_canvas);
  setDayRRChartOption(rr_chart, rr_data);
  return rr_chart;
}

// 呼吸
function doAhChart () {
  const ah_data = {}
  const ah_canvas = document.getElementById(AhChartId.value)
  const ah_chart = echarts.init(ah_canvas);
  ah_data.nointention = (data.value.intention_middle && data.value.intention_middle.length > 0) ? data.value.intention_middle : null

  ah_data.start = data.value.starttime; // starttime 字段
  ah_data.end = data.value.endtime; // endtime 字段
  ah_data.leavebed = (data.value.leavebed && data.value.leavebed.length > 0) ? data.value.leavebed : null
  ah_data.stages = data.value.stage_details

  if (data.value.ahi_series) {
    ah_data.ah = data.value.ahi_series
    setStageAH2ChartOption(ah_chart, ah_data);
  } else {
    ah_data.ah = data.value.ahi_events
    setStageAHChartOption(ah_chart, ah_data);
  }

  return ah_chart;
}

// 心率
function doHrChart () {
  hr_data.start = data.value.starttime; // starttime 字段
  hr_data.end = data.value.endtime; // endtime 字段
  hr_data.nointention = (data.value.intention_middle && data.value.intention_middle.length > 0) ? data.value.intention_middle : null
  hr_data.leavebed = data.value.leavebed
  hr_data.avg = data.value.bpm_avg
  hr_data.segments = data.value.bpm_series
  const hr_canvas = document.getElementById(HrChartId.value)
  const hr_chart = echarts.init(hr_canvas);
  setDayHRChartOption(hr_chart, hr_data);
  return hr_chart;
}

// 平均呼吸率图表
function doRrBlChart () {
  // eslint-disable-next-line no-unused-vars
  const rr_bl_canvas = document.getElementById(rrBLChartId.value)
  const rr_bl_chart = echarts.init(rr_bl_canvas);
  initChartDates(reports_data.value, selectedReportDate.value)
  setRRBaselineChartOption(reports_data.value, rr_bl_chart, chart_dates);
  return rr_bl_chart;
}

// 最低心率趋势
function doHrBlChart () {
  // eslint-disable-next-line no-unused-vars
  const hr_bl_canvas = document.getElementById(hrBLChartId.value)
  const hr_bl_chart = echarts.init(hr_bl_canvas);
  initChartDates(reports_data.value, selectedReportDate.value)
  setHRBaselineChartOption(reports_data.value, hr_bl_chart, chart_dates);
  return hr_bl_chart;
}

// hrv rmssd 趋势
function doHrvBlChart () {
  // eslint-disable-next-line no-unused-vars
  const hrv_bl_canvas = document.getElementById(hrvBLChartId.value)
  const hrv_bl_chart = echarts.init(hrv_bl_canvas);
  initChartDates(reports_data.value, selectedReportDate.value)
  setHRVBaselineChartOption(reports_data.value, hrv_bl_chart, chart_dates);
  return hrv_bl_chart;
}

// ahi 趋势
function doAhiChart () {
  // eslint-disable-next-line no-unused-vars
  const ahi_canvas = document.getElementById(ahiChartId.value)
  const ahi_chart = echarts.init(ahi_canvas);
  initChartDates(reports_data.value, selectedReportDate.value)
  setAHIChartOption(reports_data.value, ahi_chart, chart_dates);
  return ahi_chart;
}

// 初始化日期（横轴）
const initChartDates = (reports, reportKey = '') => {
  const max_chart_days = 14; // 定义图表的最大天数为14天

  let today;
  if (reportKey && reports) { // 如果提供了有效的报告键且报告存在
    const reportDate = new Date(reportKey); // 获取指定报告键的日期，并转换为日期对象
    const month = reportDate.getMonth() + 1; // 获取月份（从0开始，需要加1）
    const day = reportDate.getDate(); // 获取日期
    const formattedKey = `${month}/${day}`; // 格式化为 "M/D" 形式的字符串
    if (reports[formattedKey]) { // 如果reports中存在对应的键
      today = reportDate;
    } else {
      today = new Date(); // 如果没有找到对应的报告，则使用当前日期
    }
  } else {
    today = new Date(); // 如果没有提供报告键，则使用当前日期
  }
  today.setHours(0, 0, 0, 0); // 将时间部分设为零，确保日期从当天的零点开始

  let todayTS = today.getTime() / 1000; // 将当前日期转换为时间戳（以秒为单位）
  resetDates(); // 调用一个函数（假设在其他地方定义），用于重置日期
  for (let i = 0; i < max_chart_days; i++) { // 循环14次，每次处理一天
    const shortDate = formatDateString(todayTS * 1000, 'MMdd', false);
    // 将当前时间戳转换为指定格式的短日期字符串
    chart_dates[shortDate] = -1; // 将短日期字符串作为键，初始化值为-1
    todayTS -= 24 * 3600; // 将时间戳减去一天的秒数，准备处理前一天
  }
}

const getSleepEfficiency = (tst, tib) => {
  return Math.round((tst / tib) * 100);
};
const getSpanStyle = (data, detail, type) => {
  const tag = getTagOrTag2(data, detail, type);
  return {
    color: '#FFFFFF',
    backgroundColor: tag.color,
    padding: '2px 4px',
    borderRadius: '4px',
    marginLeft: '8px'
  };
};
const ledColor = ['#00BB35', '#FFBA2F', '#FB8700', '#E92D2E', '#CCC'];

const getTag = (detail, type) => {
  if (!detail) {
    return { name: '暂无', color: '#A0A0A0' };
  }
  if (type === 'sri' && detail.value < 0) {
    return { name: '暂无', color: '#A0A0A0' };
  }
  if (type === 'se') {
    if (detail > 90) {
      return { name: '理想', color: '#32CD32' };
    } else if (detail > 80) {
      return { name: '偏低', color: '#FFD700' };
    } else {
      return { name: '过低', color: '#FF4500' };
    }
  }
  const rank = detail.rank;
  switch (rank) {
    case -2:
      return { name: type === 'sri' ? '不规律' : '过短', color: '#FF4500' };
    case -1:
      return { name: type === 'sri' ? '一般' : '偏短', color: '#FFD700' };
    case 0:
      return { name: '理想', color: '#32CD32' };
    case 1:
      return { name: type === 'sri' ? '一般' : '偏长', color: '#FFD700' };
    case 2:
      return { name: type === 'sri' ? '不规律' : '过长', color: '#FF4500' };
    default:
      return { name: '-', color: '#A0A0A0' };
  }
};
const getTag2 = (detail, type) => {
  //
  // if (!detail) {
  //   return { name: '暂无', color: '#A0A0A0' };
  // }
  if (type === 'sri' && detail?.value < 0) {
    return { name: '暂无', color: ledColor[4], len: 0 };
  }
  const rank = detail?.rank;
  switch (rank) {
    case 0:
      return { name: '理想', color: ledColor[0], len: Math.round(parseFloat(detail.score) * 100) };
    case 1:
      return { name: '良好', color: ledColor[0], len: Math.round(parseFloat(detail.score) * 100) };
    case -2:
      return { name: '偏长', color: ledColor[1], len: Math.round(parseFloat(detail.score) * 100) };
    case -4:
      return { name: '过长', color: ledColor[3], len: Math.round(parseFloat(detail.score) * 100) };
    case -1:
      if (type === 'tst' || type === 'deep' || type === 'rem') { return { name: '偏少', color: ledColor[1], len: Math.round(parseFloat(detail.score) * 100) }; } else if (type === 'se' || type === 'sri') { return { name: '偏低', color: ledColor[1], len: Math.round(parseFloat(detail.score) * 100) }; }
      return { name: '偏短', color: ledColor[1], len: Math.round(parseFloat(detail.score) * 100) };
    case -3:
      if (type === 'tst' || type === 'deep' || type === 'rem') { return { name: '过少', color: ledColor[1], len: Math.round(parseFloat(detail.score) * 100) }; } else if (type === 'se' || type === 'Sri') { return { name: '过低', color: ledColor[1], len: Math.round(parseFloat(detail.score) * 100) }; }
      return { name: '过短', color: ledColor[3], len: Math.round(parseFloat(detail.score) * 100) };
    default:
      return { name: '', color: '#A0A0A0', len: 0 };
  }
};

const getTagOrTag2 = (data, detail, type) => {
  let tag_type = type
  if (type === 'dst') {
    tag_type = 'deep'
  }
  if (type === 'sri') {
    if (data.sri === -1) {
      return getTag2({
        rank: 0,
        score: '1.00',
        value: -1
      }, tag_type)
    }
  }
  return data.new_ranks ? getTag2(data.new_ranks[type], tag_type) : getTag(detail, type);
};

const downloadExcel = async (type) => {
  let days = selectedReportDate.value
  let device = selectedDevice.value
  if (type === 'history') {
    days = 'history'
  } else if (type === 'all') {
    device = 'all'
  }
  const axiosInstance = createAxiosInstance();
  const request_data = await axiosInstance.get(`/report/download/${device}/${days}/`);
  const data = request_data.data.data
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('睡眠报告');

  // 添加表头
  worksheet.addRow([
    '设备',
    '日期', '总睡眠时长', '入睡速度', '睡眠中断', '深睡时间', '快速眼动睡眠时间', '睡眠规律性', '睡眠片段化指数', '清醒',
    'AHI', 'NREM', 'REM', '睡眠平均呼吸率',
    '快速眼动睡眠时间', '快速眼动睡眠效率', '核心睡眠时间', '核心睡眠效率', '深睡时间', '深睡效率',
    '最低睡眠心率', '平均睡眠心率', '睡眠期HRV_RMSSD', '睡眠期HRV_LF/HF', '光照影响的睡眠比例', '平均温度', '平均湿度'
  ]);
  console.log(data)
  // 添加数据
  data.forEach(entry => {
    const date = entry.date;
    const device = entry.device;
    const online = entry.reports.online;
    const summary = entry.reports.summmary;
    worksheet.addRow([
      device,
      date,
      online.tst, online.sol, online.waso, online.deep_time, online.rem_time, online.sri, online.sfi, online.sleep_dur_1,
      online.ahi, online.ahi_345, online.ahi_2, online.avg_resp,
      summary.rem_sleep_time, summary.rem_efficiency, summary.core_sleep_time, summary.core_sleep_efficiency, summary.deep_sleep_time, summary.deep_sleep_efficiency,
      summary.min_sleep_heart_rate, summary.avg_sleep_heart_rate, summary.avg_hrv_rmssd, summary.avg_hrv_radio, summary.high_illuminance, summary.avg_temperature, summary.avg_humidity
    ]);
  });

  // 生成Excel文件并触发下载
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  if (deviceinfo.value.bednumber) {
    saveAs(blob, `睡眠报告-${deviceinfo.value.bednumber}号床-${selectedDevice.value}-${selectedReportDate.value}.xlsx`);
  } else {
    saveAs(blob, `睡眠报告-${deviceinfo.value.info}-${selectedDevice.value}-${selectedReportDate.value}.xlsx`);
  }
};

// 添加窗口大小变化处理
const handleResize = () => {
  // 处理现有图表的resize
  // ... existing resize code ...
  
  // 处理小睡图表的resize
  Object.values(chartInstances.value).forEach(charts => {
    Object.values(charts).forEach(chart => chart.resize())
  })
}

// 组件卸载时清理
onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  // 销毁小睡图表实例
  Object.values(chartInstances.value).forEach(charts => {
    Object.values(charts).forEach(chart => chart.dispose())
  })
})

onMounted(() => {
  changeDate()
});
</script>

<style scoped>
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px; /* 如果需要按钮和选择框之间增加间隙 */
}

.data-table {
  width: 80%;
  border-collapse: collapse;
}

.data-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: left;
}

.data-table strong {
  font-weight: bold;
}

/* 设置第一列的宽度 */
.data-table td:first-child {
  width: 50%; /* 你可以根据需要调整这个值 */
}

.user-info-table {
  width: 100%;
  border-collapse: collapse;
}

.user-info-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: left;
}

.user-info-table strong {
  font-weight: bold;
}

.report-time td {
  border: 1px solid #ddd;
  padding: 8px;
}

.report-container {
  max-width: 210mm; /* A4纸宽度 */
  min-height: 297mm; /* A4纸高度 */
  margin: 0 auto; /* 居中 */
  padding: 10px; /* 内边距 */
  box-sizing: border-box;
  background: #fff;
  font-family: 'Arial', sans-serif;
  font-size: 12px;

}

.report-title {
  margin-top: -5%;
  text-align: center;
  color: #333;
  font-size: 15px;
}

.header-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -1%;

}

.report-section h4 {
  color: black;
  font-size: 12px;
}

p {
  color: #666;
  line-height: 1.5;
  font-size: 5px;
}

strong {
  font-weight: bold;
}

.report-section div {
  width: 100%;
  box-sizing: border-box;
}

.data-table td.right-align {
  text-align: right;
}

@media print {
  @page {
    size: A4;
    margin: 20mm;
    counter-increment: page;
  }

  .report-container {
    page-break-inside: avoid;
  }

  .report-section {
    page-break-inside: avoid;
  }

  .report-section h4,
  .report-section div,
  .report-time,
  .grid,
  .grid_0 {
    page-break-inside: avoid;
  }

  .page-footer::after {
    content: "Page " counter(page);
  }
}

.nap-charts {
  width: 100%;
}

.nap-item {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
}

.nap-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.chart {
  height: 300px;
  margin: 10px 0;
}

</style>
